import React, {FC, useEffect, useState} from "react";
import {Box, Center, Flex, Image, Spinner} from "@chakra-ui/react";
import {URLPaths} from "../../config/application/URLPaths";
import {WebPUtils} from "../../shared/utilities/WebPUtils";
import {ImageItem, Product} from "../../shared/entities/Product/Product";
import {imageSizes} from "../../theme/foundations/breakpoints";
import ReactDOM from "react-dom";
import {useLocation} from "react-router";
import {BoxRowStyleConfig} from "../../views/Forms/FormStyleConfigs";
import {Icon} from "@chakra-ui/icons";
import {FiChevronLeft, FiChevronRight} from "react-icons/all";
import CustomLink from "../Link/CustomLink";
import {useSelector} from "react-redux";

interface ImageCustomProps {
    sizeInPercent?: number;
    elem: Product;
    showOnHover?: boolean;
    upload?: boolean;
    onIndexChange?: (index: number) => void;
    parentCurrentIndex?: number;
    hideArrows?: boolean;
    currentSize?: keyof typeof imageSizes;
    imageIndex?: number;
    sizeBackend?: string;
    isMobile?: boolean;
    forceExternal?: boolean;
}

const ImageCustom: FC<ImageCustomProps> = ({
                                               // uploadImages,
                                               elem,
                                               sizeInPercent = 100,
                                               imageIndex = 0,
                                               showOnHover = true,
                                               upload = false,
                                               onIndexChange,
                                               parentCurrentIndex,
                                               hideArrows = true,
                                               currentSize = "original",
                                               sizeBackend = "ORIGINAL",
                                               isMobile = false,
                                               forceExternal = false
                                           }) => {
    const location = useLocation();
    const isHomePage = (location.pathname === URLPaths.HOME.link || location.pathname === URLPaths.HOME_ARCHIVE.link);
    const isRender = new URLSearchParams(useLocation().search).get("is_render") === "true";
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0});
    const [currentIndex, setCurrentIndex] = useState<number>(imageIndex);
    const [images, setImages] = useState<Array<ImageItem>>([]);
    const imageItems = useSelector((state: any) => state.ImageReducer.images);

    function findIndexByUuid(item: Product): number {
        if (item && item.primaryImageUuid && item.images && item.images.length > 0) {
            return item?.images.findIndex(image => image.uuid === item.primaryImageUuid);
        }
        return 0
    }
    useEffect(() => {
        setCurrentIndex(imageIndex);
    }, [imageIndex])

    useEffect(() => {
        if (upload) {
            if (imageItems && imageItems.length > 0) {
                let setDefault = 0;
                if (imageItems.filter((item: ImageItem) => !item.file).length === imageItems.length) {
                    if (elem && elem.images) {
                        setDefault = findIndexByUuid(elem);
                        setCurrentIndex(Number(setDefault));
                    }
                }
                setImages(imageItems);
                if (parentCurrentIndex && parentCurrentIndex !== currentIndex) {
                    setCurrentIndex(parentCurrentIndex);
                }
                if (parentCurrentIndex === 0 && setDefault === 0) {
                    setCurrentIndex(parentCurrentIndex);
                }
            } else {
                setImages([])
            }
        }
        if (elem && elem.images && elem.images.length > 0 && !upload) {
            const newImages = elem.images.map((image) => ({
                url: WebPUtils.getImageUrl(sizeBackend, image.uuid, isRender),
                file: null,
                uuid: image.uuid
            }));
            setCurrentIndex(findIndexByUuid(elem));
            setImages(newImages)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elem, imageItems])

    const handleArrowClick = (direction: 'left' | 'right') => {
        if (images.length === 0) {
            return;
        }
        if (direction === 'left') {
            setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
        } else {
            setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
        }
    };

    useEffect(() => {
        if (onIndexChange) {
            onIndexChange(currentIndex);
        }
    }, [currentIndex, onIndexChange]);

    let hoverTimeout: NodeJS.Timeout | null = null;

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsHovered(false);
        hoverTimeout = setTimeout(() => {
            setIsHovered(true);
            setMousePosition({x: e.clientX, y: e.clientY});
        }, 100);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
        }
    };

    const borderRadiusMap = {
        small: {borderRadius: "sm"},
        medium: {borderRadius: "md"},
        large: {borderRadius: "lg"},
        original: {borderRadius: "default"},
        subgeneral: {
            borderTopRightRadius: "lg",
            borderTopLeftRadius: "lg",
        },
        general: {borderRadius: "lg"},
        cardPreview: {borderRadius: "sm"},
        another: {borderRadius: "md"},
        additional: {borderRadius: "default"},
        cart: {borderRadius: "default"},
        default: {borderRadius: "default"},
    };

    const getBorderRadius = (currentSize: keyof typeof imageSizes) => {
        return borderRadiusMap[currentSize];
    };

    return (
        <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {images.length > 0 && (
                <Flex alignItems="start" flexDirection="column" userSelect="none">
                    {images[currentIndex] && (
                        <Box
                            {...BoxRowStyleConfig}
                            alignItems="center"
                            justifyContent="center"
                            border={upload ? "1px" : "default"}
                        >
                            {!hideArrows && images.length > 1 && (isHovered || isMobile) && (
                                <Icon
                                    as={FiChevronLeft}
                                    fontSize={24}
                                    cursor="pointer"
                                    onClick={() => handleArrowClick('left')}
                                    _hover={{color: "gray.500"}}
                                />
                            )}
                            <Flex
                                width={`${sizeInPercent}%`}
                                height={`${sizeInPercent}%`}
                                maxWidth={isMobile ? undefined : `${imageSizes[currentSize].width * sizeInPercent / 100}px`}
                            >
                                <CustomLink
                                    forceExternal={forceExternal}
                                    href={WebPUtils.getProductLink(elem)}
                                    width="100%"
                                    minHeight={`${imageSizes[currentSize].height * sizeInPercent / 100}px`}
                                >
                                    <Image
                                        fallback={
                                            <Center
                                                width={`100%`}
                                                height={`100%`}
                                            >
                                                <Spinner color="red.500"/>
                                                <Box pt={`${sizeInPercent}%`}/>
                                            </Center>
                                        }
                                        src={images[currentIndex].url}
                                        alt={elem?.name}
                                        {...getBorderRadius(currentSize)}
                                        objectFit="contain"
                                        width="100%"
                                        height="100%"
                                        cursor="pointer"
                                    />
                                </CustomLink>
                            </Flex>
                            {!hideArrows && images.length > 1 && (isHovered || isMobile) && (
                                <Icon
                                    as={FiChevronRight}
                                    fontSize={24}
                                    cursor="pointer"
                                    onClick={() => handleArrowClick('right')}
                                    _hover={{color: "gray.500"}}
                                />
                            )}
                        </Box>
                    )}
                </Flex>
            )}
            {isHovered && !isHomePage && showOnHover &&
                ReactDOM.createPortal(
                    <Box
                        position="fixed"
                        top={`${mousePosition.y}px`}
                        left={`${mousePosition.x}px`}
                        zIndex={9999}
                    >
                        <Image
                            src={images[currentIndex].url}
                            alt={`Selected ${currentIndex + 1}`}
                            height={`${imageSizes["original"].width}px`}
                            width={`${imageSizes["original"].height}px`}
                        />
                    </Box>,
                    document.body
                )}
        </Box>
    );
}

export default ImageCustom;
