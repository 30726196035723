import {PayloadAction} from "@reduxjs/toolkit"
import {Reducer} from "react"
import {ChangePrice} from "../../shared/entities/Product/ChangePrice";

export type ChangePriceState = {
    changePriceList: { prices: Array<ChangePrice>, count: number },
}
const State: ChangePriceState = {
    changePriceList: {
        prices: [],
        count: 0
    }
}

export enum ActionTypes {
    CHANGE_PRICE_LIST_ALL = "CHANGE_PRICE_LIST_ALL",
}

export const ChangePriceReducer: Reducer<ChangePriceState, PayloadAction<any>> = (state = State, action: PayloadAction<any>): ChangePriceState => {
    switch (action.type) {
        case ActionTypes.CHANGE_PRICE_LIST_ALL:
            return state = {
                ...state,
                changePriceList: action.payload,
            }
        default:
            return state;
    }
}


