import {ImageItem} from "../../shared/entities/Product/Product";
import {PayloadAction, Reducer} from "@reduxjs/toolkit";

export type ImageState = {
    images: ImageItem[];
};

const initialState: ImageState = {
    images: []
};

export enum ImageActionTypes {
    ADD_IMAGES = "ADD_IMAGES",
    UPDATE_IMAGE = "UPDATE_IMAGE",
    REMOVE_IMAGE = "REMOVE_IMAGE",
    CLEAR_IMAGES = "CLEAR_IMAGES"
}

export const ImageReducer: Reducer<ImageState, PayloadAction<any>> = (
    state = initialState,
    action: PayloadAction<any>
): ImageState => {
    switch (action.type) {
        case ImageActionTypes.ADD_IMAGES:
            return {
                ...state,
                images: [...state.images, ...action.payload]
            };

        case ImageActionTypes.UPDATE_IMAGE:
            return {
                ...state,
                images: state.images.map((image, idx) => {
                        if (idx === action.payload.index) {
                            let out = action.payload.image;
                            out.uuid = image.uuid;
                            return out
                        } else {
                            return image
                        }
                    }
                )
            };

        case ImageActionTypes.REMOVE_IMAGE:
            return {
                ...state,
                images: state.images.filter((_, idx) => idx !== action.payload)
            };

        case ImageActionTypes.CLEAR_IMAGES:
            return {
                ...state,
                images: []
            };

        default:
            return state;
    }
};

export default ImageReducer;
