import {
    ICommonNumberObject,
    ICommonStringObject
} from './constantsInterfaces';


export enum ProductTypes {
    CATEGORY = 'CATEGORY',
    SUBCATEGORY = 'SUBCATEGORY',
    OPTIONS = 'OPTIONS',
    PRODUCT = 'PRODUCT',
    CUSTOM = 'CUSTOM',
    REORDER = 'REORDER',
    OTHER = 'OTHER',
    ADDITIONAL = 'ADDITIONAL',
    ANOTHER = 'ANOTHER',
    NEED_MORE_PRODUCTS = 'NEED_MORE_PRODUCTS',
};

export enum DocumentTypes {
    Invoice = 0,
    PackingSleep = 1
};

export const ORDER_STATUSES: ICommonNumberObject = {
    PENDING: 0,
    PAID: 1
};

export enum FieldType {
    Input = "input",
    Select = "select",
    Checkbox = "checkbox",
    Date = "date",
    DateRange = "date_range"
}

//api core-service

export const CLIENT_BASE_API_PREFIX = "api/client";
export const PRODUCT_API_PREFIX = `${CLIENT_BASE_API_PREFIX}/product`;
export const ORDER_API_PREFIX = `${CLIENT_BASE_API_PREFIX}/order`;
export const ORDER_PRODUCTS_API_PREFIX = `${CLIENT_BASE_API_PREFIX}/order-products`;
export const CHANGE_PRICE_API_PREFIX = `${CLIENT_BASE_API_PREFIX}/change-price`;


//api auth-service
export const AUTH_BASE_API_PREFIX = "api/auth";
export const AUTH_API_PREFIX = `${AUTH_BASE_API_PREFIX}`;

//api admin-service
export const ADMIN_BASE_API_PREFIX = "api/admin";
export const COMPANY_API_PREFIX = `${ADMIN_BASE_API_PREFIX}/company`;
export const STATE_API_PREFIX = `${ADMIN_BASE_API_PREFIX}/state`;
export const ROLE_API_PREFIX = `${ADMIN_BASE_API_PREFIX}/role`;
export const PERMISSION_API_PREFIX = `${ADMIN_BASE_API_PREFIX}/permission`;
export const REQUEST_REGISTRATION_API_PREFIX = `${ADMIN_BASE_API_PREFIX}/request-registration`;
export const USER_API_PREFIX = `${ADMIN_BASE_API_PREFIX}/user`;
export const SUBSCRIBE_API_PREFIX = `${ADMIN_BASE_API_PREFIX}/subscribe`;

//api file-service
export const FILE_BASE_API_PREFIX = "api/file";
export const IMAGE_API_PREFIX = `${FILE_BASE_API_PREFIX}/image`;
export const COMMON_API_PREFIX = `${FILE_BASE_API_PREFIX}/common`;

//api mail-service
export const MAIL_BASE_API_PREFIX = "api/mail";
export const MAIL_API_PREFIX = `${MAIL_BASE_API_PREFIX}`;