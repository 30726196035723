import {AuthorizationData} from "../../shared/entities/Auth/AuthorizationData";
import {CustomError} from "../../shared/errors/CustomErrors";
import {Option} from "../../shared/utilities/OptionT";
import {APIDao, Requester} from "../APIRequester";
import {AUTH_API_PREFIX} from "../../common/constants";
class AuthorizationRepository<T> {
    private dao: Requester

    constructor(dao: Requester) {
        this.dao = dao
    }

    public async authorize(data: any): Promise<Option<any>> {
        sessionStorage.setItem("LOGIN", data.loginNoCrypt)
        delete data.loginNoCrypt;
        sessionStorage.setItem('lastRefreshTime', new Date().getTime().toString());
        return await this.dao.postRequest<any>(`${AUTH_API_PREFIX}/auth-user`, {data})
            .then(res => {
                const result: Option<any> = {
                    Some: res?.data
                }
                return result
            }).catch((err: CustomError) => {
                sessionStorage.removeItem("LOGIN")
                sessionStorage.removeItem("DEALERSHIP")
                sessionStorage.removeItem("lastRefreshTime")
                const result: Option<any> = {
                    Some: false,
                    None: err
                }
                return result
            })
    }

    public async getPublicKey(): Promise<Option<any>> {
        return await this.dao.getRequest<any>(`${AUTH_API_PREFIX}/key`, {params: {}})
            .then(res => {
                const result: Option<any> = {
                    Some: res?.data
                }
                return result
            }).catch((err: CustomError) => {
                const result: Option<any> = {
                    //@ts-ignore
                    Some: [],
                    None: err
                }
                return result
            })
    }

    public async checkAuh(): Promise<Option<any>> {
        sessionStorage.setItem('lastRefreshTime', new Date().getTime().toString());
        return await this.dao.postRequest<any>(`${AUTH_API_PREFIX}/validate`, {})
            .then(res => {
                const result: Option<any> = {
                    Some: res?.data
                }
                sessionStorage.setItem("LOGIN", res?.data.email)
                return result
            }).catch((err: CustomError) => {
                sessionStorage.removeItem("LOGIN")
                sessionStorage.removeItem("DEALERSHIP")
                sessionStorage.removeItem("lastRefreshTime")
                const result: Option<any> = {
                    Some: false,
                    None: err
                }
                return result
            })
    }

    public async refresh(): Promise<Option<boolean>> {
        return await this.dao.postRequest(`${AUTH_API_PREFIX}/refresh`, {}).then(res => {
            const result: Option<boolean> = {
                Some: true
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<boolean> = {
                Some: false,
                None: err
            }
            return result
        })
    }

    public async logOut(): Promise<Option<boolean>> {
        return await this.dao.postRequest(`${AUTH_API_PREFIX}/logout`, {}).then(res => {
            const result: Option<boolean> = {
                Some: true
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<boolean> = {
                Some: false,
                None: err
            }
            return result
        })
    }
}

export const AuthorizationRepositoryInstance = new AuthorizationRepository<AuthorizationData>(APIDao)
