import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router";
import {Box, Checkbox, Flex, FormControl} from "@chakra-ui/react";
import {BreadcrumbsData} from "../../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {useAppSelector} from "../../../redux/Store";
import {UserController} from "../../../controllers/UserController";
import {MainButton} from "../../../components/Buttons/MainButton";
import {Field, Form, Formik} from "formik";
import {BoxRowStyleConfig, BoxStyleConfig, FormStyleConfig} from "../FormStyleConfigs";
import {LabelStyled} from "../../../components/Inputs/LabelStyled";
import {InputControlStyled} from "../../../components/Inputs/InputControlStyled";
import {ModalService} from "../../../service/Modal/ModalService";
import {ClientValidator} from "../../../controllers/Validation/ClientValidator";
import {SelectControlStyled} from "../../../components/Inputs/SelectControlStyled";
import {Breadcrumbs} from "../../../components/Breadcrumbs/Breadcrumbs";
import {Loader} from "../../../components/Loader/Loader";
import {AddDealershipModal} from "../../Modal/AddDealershipModal";
import {SelectModal} from "../../../redux/Modal/ModalReducer";
import DealershipsForUsers from "./DealershipsForUsers";
import {FiPaperclip, FiPlusCircle} from "react-icons/all";
import IconWithTooltip from "../../../components/Icon/IconWithTooltip";
import {Company} from "../../../shared/entities/Company/Company";
import {TextareaControl} from "formik-chakra-ui";
import {ClientMaskedInput} from "../../../components/Inputs/ClientMaskedInput";
import {hasPermission} from "../../../features/Permission/AllowedAccess";
import {
    clientCreatePermission,
    clientEditPermission,
    clientViewPermission
} from "../../permissions";
import {URLPaths} from "../../../config/application/URLPaths";
import {setBreadcrumbsLinks} from "../../../redux/Breadcrumbs/BreadcrumbsReducer";


interface ClientFormProps {
    links: Array<BreadcrumbsData>;
    isEditing: boolean;
    isParent?: boolean;
    companyId?: string;
    dealershipId?: string;
}

export const ClientForm: FC<ClientFormProps> = ({
                                                    links,
                                                    isEditing,
                                                    isParent = false,
                                                    companyId: propCompanyId,
                                                    dealershipId: propDealershipId
                                                }) => {
    const {paramCompanyId, paramDealershipId} = useParams()

    const companyId = isParent ? propCompanyId : paramCompanyId;
    const dealershipId = isParent ? propDealershipId : paramDealershipId;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const userController = new UserController(dispatch)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [company, setCompany] = useState<Company | undefined>()
    const modalService = new ModalService(dispatch)
    const containsView = location.pathname.includes("view") || isParent;
    const debug = process.env.NODE_ENV === "development";
    const createFlag = !(isEditing || containsView);
    const roles = useAppSelector(store => store.RoleReducer?.roleList)
    const states = useAppSelector(state => [...state.StatesReducer.stateList])
    const hasUserPrivileges = hasPermission(clientCreatePermission) || hasPermission(clientEditPermission) || hasPermission(clientViewPermission);
    const [refreshUser, setRefreshUser] = useState(false);
    const [selectedDealershipIndex, setSelectedDealershipIndex] = useState(0);

    const [isCreateDealerShip, setCreateDealerShip] = useState<boolean>(false)

    let buttonLabel: {} | null | undefined;

    if (isEditing) {
        buttonLabel = isCreateDealerShip ? 'Create Dealership' : 'Update';
    } else {
        buttonLabel = 'Add Company';
    }
    const [initialValues, setInitialValues] = useState<any>({
        company: {
            name: "",
        },
        dealership: {name: "", id: -1, email: "", phone: "", noTax: false, noShippingFee: false, localStatus: false},
        copyShippingToBilling: false,
        billingAddress: {state: 1, city: "", street: "", zip: ""},
        shippingAddress: {state: 1, city: "", street: "", zip: ""},
    });

    const selectedDealership =
        company?.dealerships &&
        company.dealerships.length > selectedDealershipIndex
            ? company.dealerships[selectedDealershipIndex]
            : undefined;
    const getStateId = (address: any, stateName: any) =>
        address?.state?.name && states.find((elem) => elem.name === stateName)?.id;

    const getAddressInfo = (address: any) => {
        return {
            state: getStateId(address, address?.state?.name) || 0,
            city: address?.city || "",
            street: address?.street || "",
            zip: address?.zip || "",
        };
    };
    const handleDealership = () => {
        modalService.setModal({
            selModel: SelectModal.ADD_DEALERSHIP,
            selCommonContent: company?.dealerships,
        });
    }

    useEffect(() => {
        if (isCreateDealerShip) {
            setInitialValues((prevValues: any) => ({
                    company: {
                        name: prevValues.company.name,
                    },
                    dealership: {
                        name: "",
                        id: "",
                        email: "",
                        phone: "",
                        noTax: false,
                        noShippingFee: false,
                        localStatus: false
                    },
                    dealerships: prevValues.dealerships,
                    copyShippingToBilling: true,
                    billingAddress: {state: 1, city: "", street: "", zip: ""},
                    shippingAddress: {state: 1, city: "", street: "", zip: ""},
                })
            )
        }
    }, [isCreateDealerShip])

    const processPhoneNumber = (phone: string) => {
        if (phone.startsWith('+1')) {
            return phone.slice(2); // Удаляем первые два символа, если номер начинается с +1
        } else if (phone.startsWith('1')) {
            return phone.slice(1); // Удаляем первый символ, если номер начинается с 1
        }
        return phone; // Возвращаем номер без изменений, если он не начинается с 1 или +1
    }

    useEffect(() => {
        if (!createFlag) {
            if (company && roles) {
                setInitialValues({
                    company: {
                        name: company ? company.name : ""
                    },
                    dealerships: company ? company.dealerships.map(item => (
                        {id: item.id, name: item.name})) : [],
                    dealership: {
                        name: company ? selectedDealership?.name : "",
                        id: company ? selectedDealership?.id : "",
                        email: company ? selectedDealership?.email : "",
                        phone: company ? processPhoneNumber(selectedDealership?.phone || "") : "",
                        noTax: company ? selectedDealership?.noTax : false,
                        localStatus: company ? selectedDealership?.localStatus : false,
                        noShippingFee: company ? selectedDealership?.noShippingFee : false,
                    },
                    copyShippingToBilling: selectedDealership?.billingAddress?.id === selectedDealership?.shippingAddress?.id,
                    ...(selectedDealership?.billingAddress?.id === selectedDealership?.shippingAddress?.id
                        ? {billingAddress: getAddressInfo(selectedDealership?.shippingAddress)}
                        : {billingAddress: getAddressInfo(selectedDealership?.billingAddress)}),
                    shippingAddress: getAddressInfo(selectedDealership?.shippingAddress),
                })
            }
        }
    }, [company, roles, createFlag, selectedDealershipIndex])

    const fetchData = (update?: boolean) => {
        setBreadcrumbsLinks(dispatch, [...links, {
            link: location.pathname,
            text: `${isEditing ? "Edit #" + companyId : "Create Company"}`
        }]);
        if (companyId === sessionStorage.getItem("LOGIN")) {
            modalService.setModalData({
                onSubmit: () => {
                    modalService.deleteModalData()
                    navigate(-1)
                },
                isOpen: true,
                onOpen: () => {
                },
                onClose: () => {
                    modalService.deleteModalData()
                    navigate(-1)
                },
                modalTextData: "Editing of this user is prohibited",
                context: "error"
            })
        } else {
            if ((isEditing || containsView) && companyId) {
                setIsLoading(true)
                userController.getCompany(companyId, false).then(company => {
                    setIsLoading(false)
                    if (company.None) {
                        modalService.setModalData({
                            onSubmit: () => modalService.deleteModalData(),
                            isOpen: true,
                            onOpen: () => {
                            },
                            onClose: () => modalService.deleteModalData(),
                            modalTextData: (company?.None?.getErrorDescription ? "User: " + company?.None?.getErrorDescription() : "Not found"),
                            context: "error"
                        })
                    } else {
                        const someType = company.Some as Company;
                        setCompany(someType);
                        if (!update) {
                            if (company?.Some && Number(dealershipId)) {
                                const foundIndex = someType.dealerships.findIndex(dealer => dealer.id === Number(dealershipId));
                                setSelectedDealershipIndex(foundIndex !== -1 ? foundIndex : 0);
                            } else {
                                if (selectedDealershipIndex && selectedDealershipIndex < 1)
                                    setSelectedDealershipIndex(0)
                            }
                        }
                    }
                })
            } else {
                userController.cleanCompany()
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshUser, companyId, dealershipId])

    const handleSelectChange = (event: any) => {
        const newIndex = Number(event.target.value);
        const foundIndex = company?.dealerships.findIndex(dealer => dealer.id === newIndex);
        if (foundIndex !== undefined) {
            setSelectedDealershipIndex(foundIndex !== -1 ? foundIndex : 0);
        }
    };

    return (
        <>
            {!isParent &&
                <Breadcrumbs/>
            }
            {isLoading ? <Loader/> :
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        if (values.copyShippingToBilling) {
                            delete values.billingAddress;
                        }
                        values.createDealership = isCreateDealerShip
                        const formattedValues = {
                            ...values,
                            dealership: {
                                ...values.dealership,
                                phone: '1' + values.dealership.phone, // Добавляем "1" перед текущим номером
                            },
                        };
                        if (!isEditing) {
                            if (hasPermission(clientCreatePermission)) {
                                actions.setSubmitting(true)
                                userController.createCompany(formattedValues).then(res => {
                                    if (res?.None?.getErrorCode() === 10200) {
                                        actions.setFieldError("company.name", res?.None?.getErrorDescription())
                                        actions.setSubmitting(false)
                                        return
                                    }
                                    console.log("res", res)
                                    if (res?.None) {
                                        modalService.setModalData({
                                            onSubmit: () => modalService.deleteModalData(),
                                            isOpen: true,
                                            onOpen: console.log,
                                            onClose: () => modalService.deleteModalData(),
                                            modalTextData: (res?.None?.getErrorDescription ? "Create User: " + res?.None?.getErrorDescription() : "ошибка сервера"),
                                            context: "error"
                                        })
                                    } else {
                                        modalService.setModalData({
                                            onSubmit: () => {
                                                modalService.deleteModalData();
                                                navigate(`${URLPaths.COMPANY_EDIT.link}${res.Some}`)
                                                setRefreshUser(prevIndex => !prevIndex);
                                                setCreateDealerShip(false)
                                            },
                                            isOpen: true,
                                            onOpen: console.log,
                                            onClose: () => modalService.deleteModalData(),
                                            modalTextData: "User successfully created! " + values.company.name,
                                            context: "action"
                                        })
                                    }
                                    userController.getDealershipMinInfoAll({})
                                })
                            }
                        } else {
                            formattedValues.editCompanyId = company ? company.id : -1
                            formattedValues.editDealershipId = selectedDealership && !isCreateDealerShip ? selectedDealership.id : -1
                            if (hasPermission(clientEditPermission)) {
                                actions.setSubmitting(true)
                                userController.updateCompany(formattedValues).then(res => {
                                    if (res?.None?.getErrorCode() === 10200) {
                                        actions.setFieldError("company.name", res?.None?.getErrorDescription())
                                        actions.setSubmitting(false)
                                        return
                                    }
                                    if (res?.None) {
                                        modalService.setModalData({
                                            onSubmit: () => {
                                                modalService.deleteModalData();
                                            },
                                            isOpen: true,
                                            onOpen: console.log,
                                            onClose: () => modalService.deleteModalData(),
                                            modalTextData: (res?.None?.getErrorDescription ? "Update company: " + res?.None?.getErrorDescription() : "error company"),
                                            context: "error"
                                        })
                                    } else {
                                        modalService.setModalData({
                                            onSubmit: () => {
                                                modalService.deleteModalData();
                                                setCreateDealerShip(false)
                                                setRefreshUser(prevIndex => !prevIndex);
                                                fetchData(true)
                                            },
                                            isOpen: true,
                                            onOpen: console.log,
                                            onClose: () => modalService.deleteModalData(),
                                            modalTextData: "Company successfully updated! " + values.company.name,
                                            context: "error"
                                        })
                                    }
                                    userController.getDealershipMinInfoAll({})
                                })
                            }
                        }

                        actions.setSubmitting(false)
                    }
                    }
                    validate={values => {
                        if (values.dealership && values.dealership.id === undefined) {
                            values.dealership.id = -1;
                        }
                        setInitialValues(values)
                        if (values.copyShippingToBilling) {
                            values.billingAddress = values.shippingAddress
                        }
                    }}
                    enableReinitialize={true}
                    validationSchema={ClientValidator.GetSchema(initialValues)}
                >
                    {({isSubmitting, touched, errors, values, dirty, isValid, handleSubmit, setFieldValue}) => (
                        <FormControl {...FormStyleConfig} pt="unset">
                            <Form onSubmit={handleSubmit}>
                                <Box {...BoxRowStyleConfig}>
                                    <Box {...BoxStyleConfig}>
                                        <LabelStyled minWidth={"70px"} mb="20px">Company Information</LabelStyled>
                                        <Box {...BoxRowStyleConfig} pb={"4px"} mt="20px">
                                            <LabelStyled minWidth={"70px"}>Name</LabelStyled>
                                            <InputControlStyled name="company.name" inputProps={{
                                                maxW: "450px",
                                                autoComplete: debug ? "on" : "new-password"
                                            }} isDisabled={containsView}/>
                                        </Box>
                                    </Box>
                                    {!containsView && (
                                        <Box {...BoxStyleConfig} maxWidth={"100%"}>
                                            {(values.dealerships && !company?.defaultCompany) && (
                                                <DealershipsForUsers field='dealerships'
                                                                     userDealerships={values.dealerships}></DealershipsForUsers>
                                            )}
                                        </Box>
                                    )}
                                </Box>

                                {(!company?.defaultCompany && !containsView) && (
                                    <Box ml="auto" pt={4}>
                                        <IconWithTooltip icon={FiPlusCircle}
                                                         tooltipMessage={'Add New Dealership'}
                                                         isDisabled={isCreateDealerShip}
                                                         color={"black"}
                                                         onClick={() => setCreateDealerShip(true)}/>
                                        <IconWithTooltip icon={FiPaperclip}
                                                         tooltipMessage={'Add Exist Dealership'}
                                                         color={"black"}
                                                         onClick={() => handleDealership()}/>
                                    </Box>
                                )}
                                <Flex>
                                    {(hasUserPrivileges && (isCreateDealerShip || (Array.isArray(values.dealerships) && values.dealerships.length > 0 && !createFlag && (company?.dealerships.length !== 0)))) /* role User*/ && (
                                        <>
                                            <Box {...BoxStyleConfig}
                                                 pt={(!createFlag && !isCreateDealerShip) ? 2 : 10}>
                                                {!createFlag && !isCreateDealerShip &&
                                                    <Box {...BoxRowStyleConfig} pb={2}>
                                                        <LabelStyled minWidth={"78px"}></LabelStyled>
                                                        <SelectControlStyled
                                                            maxW="450px"
                                                            name="dealership.id"
                                                            isDisabled={containsView}
                                                            onChange={handleSelectChange}
                                                        >
                                                            {company?.dealerships ? (
                                                                company.dealerships.map((dealer) => (
                                                                    <option key={dealer.id}
                                                                            value={dealer.id}>
                                                                        {dealer.name}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <option value="" disabled>
                                                                    Loading dealerships...
                                                                </option>
                                                            )}
                                                        </SelectControlStyled>
                                                    </Box>
                                                }
                                                <Box>
                                                    <Box {...BoxRowStyleConfig} pb={1}>
                                                        <LabelStyled minWidth={"78px"}>Dealership</LabelStyled>
                                                        <InputControlStyled name="dealership.name"
                                                                            inputProps={{
                                                                                maxW: "450px",
                                                                                autoComplete: debug ? "on" : "new-password"
                                                                            }}
                                                                            isDisabled={containsView}/>
                                                    </Box>
                                                    <Box {...BoxRowStyleConfig} pb={2}>
                                                        <LabelStyled minWidth={"78px"}>Email</LabelStyled>
                                                        <TextareaControl
                                                            textareaProps={{
                                                                fontSize: "14px",
                                                                autoComplete: debug ? "on" : "new-password"
                                                            }}
                                                            name={"dealership.email"}
                                                            isDisabled={containsView}
                                                            placeholder='Here is a email dealership'
                                                            size='sm'
                                                        />
                                                    </Box>
                                                    <Box {...BoxRowStyleConfig} pb={1}>
                                                        <LabelStyled minWidth={"78px"}
                                                                     htmlFor="dealership.phone">Phone</LabelStyled>
                                                        <Field name="dealership.phone"
                                                               isDisabled={containsView}
                                                               component={ClientMaskedInput}
                                                               mask="***-***-****"
                                                               placeholder="000-000-0000"/>
                                                    </Box>
                                                </Box>
                                                <Box {...BoxRowStyleConfig} >
                                                    <LabelStyled minWidth={"78px"}></LabelStyled>
                                                    <Checkbox
                                                        style={{fontSize: "14px"}}
                                                        size={"sm"}
                                                        minW={"70px"}
                                                        isChecked={values.dealership.noTax}
                                                        isDisabled={containsView}
                                                        children={"No tax"}
                                                        onChange={(ev: any) => setFieldValue("dealership.noTax", ev.target.checked, true)}
                                                    />
                                                    <Checkbox pl={2}
                                                              style={{fontSize: "14px"}}
                                                              size={"sm"}
                                                              minW={"70px"}
                                                              isChecked={values.dealership.localStatus}
                                                              isDisabled={containsView}
                                                              children={"Local Status"}
                                                              onChange={(ev: any) => setFieldValue("dealership.localStatus", ev.target.checked, true)}
                                                    />
                                                    <Checkbox
                                                        style={{fontSize: "14px"}}
                                                        size={"sm"}
                                                        ml={"auto"}
                                                        minW={"70px"}
                                                        isChecked={values.dealership.noShippingFee}
                                                        isDisabled={containsView}
                                                        children={"No shipping fee"}
                                                        onChange={(ev: any) => setFieldValue("dealership.noShippingFee", ev.target.checked, true)}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box {...BoxStyleConfig} pl={6}>
                                                <LabelStyled minWidth={"70px"}
                                                             htmlFor="Dealership">Dealership</LabelStyled>
                                                <Box {...BoxRowStyleConfig} pb={1} mt="20px">
                                                    <LabelStyled minWidth={"50px"}
                                                                 htmlFor="shippingAddress.street">Street</LabelStyled>
                                                    <InputControlStyled name="shippingAddress.street"
                                                                        inputProps={{
                                                                            maxW: "450px",
                                                                            autoComplete: debug ? "on" : "new-password"
                                                                        }}
                                                                        isDisabled={containsView}
                                                    />
                                                </Box>
                                                <Box {...BoxRowStyleConfig}>
                                                    <LabelStyled minWidth={"50px"}
                                                                 htmlFor="shippingAddress.city">City</LabelStyled>
                                                    <InputControlStyled name="shippingAddress.city"
                                                                        inputProps={{
                                                                            maxW: "450px",
                                                                            autoComplete: debug ? "on" : "new-password"
                                                                        }}
                                                                        isDisabled={containsView}/>
                                                </Box>
                                                <Box {...BoxRowStyleConfig} pb={2}>
                                                    <LabelStyled minWidth={"50px"}
                                                                 htmlFor="shippingAddress.state">State</LabelStyled>
                                                    <SelectControlStyled
                                                        name="shippingAddress.state"
                                                        isDisabled={containsView}
                                                        defaultValue={"Alaska"}
                                                        children={
                                                            [...states?.map(type => {
                                                                return <option key={type.id} value={type.id}
                                                                               children={type.name}/>
                                                            })]
                                                        }
                                                    /> </Box>
                                                <Box {...BoxRowStyleConfig}>
                                                    <LabelStyled minWidth={"50px"}
                                                                 htmlFor="shippingAddress.zip">Zip</LabelStyled>
                                                    <InputControlStyled name="shippingAddress.zip"
                                                                        inputProps={{
                                                                            maxW: "450px",
                                                                            autoComplete: debug ? "on" : "new-password"
                                                                        }}
                                                                        isDisabled={containsView}/>
                                                </Box>
                                            </Box>
                                            <Box {...BoxStyleConfig} pl={6}>
                                                <Box {...BoxRowStyleConfig} pb={1}>
                                                    <LabelStyled minWidth={"70px"} htmlFor="User Information">Billing
                                                        Address</LabelStyled>
                                                    {!containsView && (
                                                        <Checkbox
                                                            mt={"10px"}
                                                            color="gray.400"
                                                            isChecked={values.copyShippingToBilling}
                                                            children={"Same as shipping address"}
                                                            onChange={(ev: any) => setFieldValue("copyShippingToBilling", ev.target.checked, true)}
                                                        />
                                                    )}
                                                </Box>
                                                <Box {...BoxRowStyleConfig} pb={1} mt="20px">
                                                    <LabelStyled minWidth={"50px"}>Street</LabelStyled>
                                                    <InputControlStyled name="billingAddress.street"
                                                                        inputProps={{
                                                                            maxW: "450px",
                                                                            autoComplete: debug ? "on" : "new-password"
                                                                        }}
                                                                        isDisabled={values.copyShippingToBilling || containsView}
                                                    />
                                                </Box>
                                                <Box {...BoxRowStyleConfig}>
                                                    <LabelStyled minWidth={"50px"}>City</LabelStyled>
                                                    <InputControlStyled name="billingAddress.city"
                                                                        inputProps={{
                                                                            maxW: "450px",
                                                                            autoComplete: debug ? "on" : "new-password"
                                                                        }}
                                                                        isDisabled={values.copyShippingToBilling || containsView}
                                                    />
                                                </Box>
                                                <Box {...BoxRowStyleConfig} pb={2}>
                                                    <LabelStyled minWidth={"50px"}>State</LabelStyled>
                                                    <SelectControlStyled
                                                        name="billingAddress.state"
                                                        isDisabled={values.copyShippingToBilling || containsView}
                                                    >
                                                        {[
                                                            ...states?.map(type => {
                                                                return <option key={type.id}
                                                                               value={type.id}>{type.name}</option>
                                                            }),
                                                        ]}
                                                    </SelectControlStyled>
                                                </Box>
                                                <Box {...BoxRowStyleConfig}>
                                                    <LabelStyled minWidth={"50px"}>Zip</LabelStyled>
                                                    <InputControlStyled name="billingAddress.zip"
                                                                        inputProps={{
                                                                            maxW: "450px",
                                                                            autoComplete: debug ? "on" : "new-password"
                                                                        }}
                                                                        isDisabled={values.copyShippingToBilling || containsView}
                                                    />
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                </Flex>
                                {!containsView && (
                                    <MainButton
                                        mt="20px"
                                        minWidth="120px"
                                        ml="auto"
                                        type="submit"
                                        isLoading={isSubmitting}
                                    >
                                        {buttonLabel}
                                    </MainButton>
                                )}
                                <AddDealershipModal/>
                            </Form>
                        </FormControl>
                    )}
                </Formik>
            }
        </>
    )
}
export default ClientForm;