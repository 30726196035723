import {
    accountKey,
    administratorsKey, changePriceKey,
    clientsKey, dealershipExcludeKey,
    ordersKey,
    productsKey,
    rolesKey,
    settingsKey,
    subscribeExcludeKey
} from "../common/baseRoutes";

// Orders Permissions
export const orderCreatePermission = `${ordersKey}#create`;
export const orderRemovePermission = `${ordersKey}#remove`;
export const orderEditPermission = `${ordersKey}#edit`;
export const orderViewPermission = `${ordersKey}#view`;
export const orderViewPartnerPermission = `${ordersKey}#view_partner`;
export const orderDealershipShowPermission = `${ordersKey}#dealership_show`;
export const orderStatusShowPermission = `${ordersKey}#status_show`;
export const orderAmountShowPermission = `${ordersKey}#amount_show`;
export const orderIdShowPermission = `${ordersKey}#id_show`;
export const orderAdminNameShowPermission = `${ordersKey}#admin_name_show`;

// Products Permissions
export const productCreatePermission = `${productsKey}#create`;
export const productRemovePermission = `${productsKey}#remove`;
export const productEditPermission = `${productsKey}#edit`;
export const productViewPermission = `${productsKey}#view`;

// Administrators Permissions
export const adminCreatePermission = `${administratorsKey}#create`;
export const adminRemovePermission = `${administratorsKey}#remove`;
export const adminEditPermission = `${administratorsKey}#edit`;
export const adminViewPermission = `${administratorsKey}#view`;

// Roles Permissions
export const roleCreatePermission = `${rolesKey}#create`;
export const roleRemovePermission = `${rolesKey}#remove`;
export const roleEditPermission = `${rolesKey}#edit`;
export const roleViewPermission = `${rolesKey}#view`;

// Clients Permissions
export const clientCreatePermission = `${clientsKey}#create`;
export const clientRemovePermission = `${clientsKey}#remove`;
export const clientEditPermission = `${clientsKey}#edit`;
export const clientViewPermission = `${clientsKey}#view`;

// Subscribe_Exclude Permissions
export const subscribeExcludeCreatePermission = `${subscribeExcludeKey}#create`;
export const subscribeExcludeRemovePermission = `${subscribeExcludeKey}#remove`;
export const subscribeExcludeViewPermission = `${subscribeExcludeKey}#view`;

// Change_Price Permissions
export const changePriceCreatePermission = `${changePriceKey}#create`;
export const changePriceRollbackPermission = `${changePriceKey}#rollback`;
export const changePriceViewPermission = `${changePriceKey}#view`;

// Settings Permissions
export const settingsCreatePermission = `${settingsKey}#create`;
export const settingsRemovePermission = `${settingsKey}#remove`;
export const settingsEditPermission = `${settingsKey}#edit`;
export const settingsViewPermission = `${settingsKey}#view`;
export const dealershipExcludeSelectPermission = `${dealershipExcludeKey}#select`;

// Account Permissions
export const accountPermission = `${accountKey}#view`;
