import {CustomError} from "../../shared/errors/CustomErrors";
import {Option} from "../../shared/utilities/OptionT";
import {APIDao, Requester} from "../APIRequester";
import {CHANGE_PRICE_API_PREFIX, ORDER_API_PREFIX} from "../../common/constants";
import {ChangePrice} from "../../shared/entities/Product/ChangePrice";
import {Order} from "../../shared/entities/Order/Order";

/**
 * UserRepository.
 *
 * Responsible for fetching data from backend
 * by callind APIDao and handling various data
 * coming from API
 *
 * Data is passed to UserService in a form of
 * an @type Option<T> type, where @param{Some: T} is either
 * a valid data, expected from the server,
 * or, in case of error, a default safe value,
 * for an object of array (kinda like default
 * values for types in Go),and @param{None: CustomError}
 * is either an error or undefined
 */
export class ChangePriceRepository<T> {
    private dao: Requester

    constructor(dao: Requester) {
        this.dao = dao
    }

    public async addChangePrice(data: any): Promise<Option<any>> {
        return await this.dao.postRequest<any>(`${CHANGE_PRICE_API_PREFIX}/add`, {data}).then(res => {
            const result: Option<any> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }
    public async rollbackChangePrice(params: {}): Promise<Option<any>> {
        return await this.dao.postRequest<any>(`${CHANGE_PRICE_API_PREFIX}/rollback`, {params: {...params}}).then(res => {
            const result: Option<any> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }
    public async getChangePriceAll(data: any): Promise<Option<any>> {
        return await this.dao.postRequest<{
            prices: Array<ChangePrice>,
            count: number
        }>(`${CHANGE_PRICE_API_PREFIX}/all`, data).then(res => {
            const result: Option<any> = {
                Some: {
                    prices: res?.data?.prices,
                    count: res?.data?.count
                }
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                //@ts-ignore
                Some: {
                    prices: [],
                    count: 0
                },
                None: err
            }
            return result
        })
    }
}

export const ChangePriceRepositoryInstance = new ChangePriceRepository(APIDao)
