import React, {useEffect, useState} from 'react';
import {
    Box, Checkbox, Flex,
    FormControl,
    InputGroup,
    InputRightElement, Progress, Text, useToast
} from '@chakra-ui/react';

import {Form, Formik} from "formik";
import {BoxRowStyleConfig, BoxStyleConfig, FormStyleConfig} from "../FormStyleConfigs";
import {LabelStyled} from "../../../components/Inputs/LabelStyled";
import {InputControlStyled} from "../../../components/Inputs/InputControlStyled";
import {SelectControlStyled} from "../../../components/Inputs/SelectControlStyled";
import {MainButton} from "../../../components/Buttons/MainButton";
import {useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useAppSelector} from "../../../redux/Store";
import {ModalService} from "../../../service/Modal/ModalService";
import {ProductValidator} from "../../../controllers/Validation/ProductValidator";
import {ProductTypes} from "../../../common/constants";
import {ProductController} from "../../../controllers/ProductController";
import {FileController} from "../../../controllers/FileController";
import {Content, Product, ProductClass, ProductRequest} from "../../../shared/entities/Product/Product";
import {Breadcrumbs} from "../../../components/Breadcrumbs/Breadcrumbs";
import {setBreadcrumbsLinks} from "../../../redux/Breadcrumbs/BreadcrumbsReducer";
import {LinkVariantPlusIcon, LinkVariantRemoveIcon} from "../../../components/Inputs/Icons/Icons";
import {InputControl, NumberInputControl, TextareaControl} from "formik-chakra-ui";
import DiscountCost from "../../Products/Discount/DiscountCost";
import PhotoUploadManager from "./PhotoUploadManager";
import {WebPUtils} from "../../../shared/utilities/WebPUtils";
import {URLPaths} from "../../../config/application/URLPaths";
import ShowPriceCost from "../../Products/Discount/ShowPriceCost";
import NavBarLinkButton from "../../../components/Link/NavBarLinkButton";
import {editFilterProduct} from "../../../theme/foundations/breakpoints";
import {ImageService} from "../../../service/ImageService";

type NewProductComponentProps = {
    edit: boolean;
};

const NewProductComponent: React.FC<NewProductComponentProps> = ({edit}) => {
        const {id} = useParams()
        const dispatch = useDispatch()
        const navigate = useNavigate();
        const fileController = new FileController(dispatch)
        const productController = new ProductController(dispatch);
        const [isLoading, setIsLoading] = useState<boolean>(false)
        const modalService = new ModalService(dispatch)
        const dealershipsMinInfo = useAppSelector(state => state.UserReducer.dealershipsMinInfo);
        const userData = useAppSelector(store => store.AuthReducer.user)
        const productGet = useAppSelector((state) => state.ProductReducer.productGet);

        const imageItems = useSelector((state: any) => state.ImageReducer.images);
        const imageService = new ImageService(dispatch);
        const toast = useToast()
        setBreadcrumbsLinks(dispatch, [
            URLPaths.HOME,
            {link: `/product/${edit ? 'edit/' : 'create/'}`, text: edit ? "Update Product" : "New Product"},
        ]);

        const defaultValuesAfterMount = {
            id: -1, name: "New Name", price: 0.0,
            parent_id: id, description: "description",
            shipping_cost: 0.0,
            shipping_local: 0.0,
            shipping_step: 0.0,
            shipping_min_size: 1,
            type_product: id === "0" ? ProductTypes.CATEGORY : ProductTypes.PRODUCT,
            order_by: 0, packingSize: 1,
            video: [], commonContent: [],
            quantityStep: 1, startValue: 1, startAdminValue: 0,
            unitOfMeasurement: "",
            primaryImage: 0,
            no_tax: false,
            hidden_product: false,
            showCart: false,
            outlinesInvoice: false,
        };

        const [initialValues, setInitialValues] = useState<any>(defaultValuesAfterMount);
        const [isShowCart, setIsShowCart] = useState(false);

        useEffect(() => {
            if (edit) {
                setIsLoading(true)
                productController.getProduct(Number(id));
            }
            dispatch({type: "PRODUCT_REMOVE"});
            imageService.clearImages();
            // AddedBreadcrumbsLinks(dispatch, {link: location.pathname, text: edit ? "Update Product" : "New Product"});
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [id]);

        function findIndexByUuid(item: Product): number {

            if (item && item.primaryImageUuid && item.images && item.images.length > 0) {
                return item?.images.findIndex(image => image.uuid === item.primaryImageUuid);
            }
            return 0
        }

        useEffect(() => {
            if (productGet) {

                setInitialValues({
                    id: productGet ? productGet.id : -1,
                    name: id === "0" ? "New Name" : productGet ? productGet.name : "",
                    url: id === "0" ? "" : productGet ? productGet.url : "",
                    price: productGet ? productGet.price : 0.0,
                    parent_id: productGet ? productGet.parentId : Number(id),
                    description: productGet ? productGet.description : "",
                    descriptionCart: productGet ? productGet.descriptionCart : "",
                    shipping_cost: productGet ? productGet.shippingCost : 0.0,
                    shipping_local: productGet ? productGet.shippingLocal : 0.0,
                    shipping_step: productGet ? productGet.shippingStep : 0.0,
                    shipping_min_size: productGet ? productGet.shippingMinSize : 1,
                    type_product: id === "0" ? ProductTypes.CATEGORY : (productGet ? productGet.type : ProductTypes.PRODUCT),
                    order_by: id === "0" ? 0 : productGet ? productGet.orderBy : 0,
                    packingSize: productGet ? productGet.packingSize : 1,
                    video: productGet && productGet.video && productGet.video.length ? productGet.video : [],
                    commonContent: productGet && productGet.discountsDTO && productGet.discountsDTO.length ? productGet.discountsDTO : [],
                    descriptionPacking: productGet ? productGet.descriptionPacking : "",
                    quantityStep: productGet ? productGet.quantityStep : 1,
                    startValue: productGet ? productGet.startValue : 1,
                    startAdminValue: productGet ? productGet.startAdminValue : 1,
                    unitOfMeasurement: productGet ? productGet.unitOfMeasurement : "",
                    no_tax: productGet ? productGet.noTax : false,
                    hidden_product: productGet ? productGet.hiddenProduct : false,
                    showCart: productGet ? productGet.showCart : false,
                    outlinesInvoice: productGet ? productGet.outlinesInvoice : false,
                    primaryImage: findIndexByUuid(productGet),
                    upload_images: productGet ? productGet.images.map((image) => ({
                        url: WebPUtils.getImageUrl("original", image.uuid, false),
                        file: null,
                        uuid: image.uuid
                    })) : []
                })
                setIsShowCart(productGet ? productGet.showCart : false)
            } else {
                setInitialValues(defaultValuesAfterMount)
            }

            setIsLoading(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [productGet, edit]);

        return (
            <>
                {isLoading &&
                    <Progress size='xs' isIndeterminate/>
                }
                <Breadcrumbs/>
                <Formik
                    initialValues={initialValues}
                    onSubmit={async (values, actions) => {
                        actions.setSubmitting(false);
                        let uploadedImages = [];
                        let uploadedImagesUrl = [];
                        if (imageItems && imageItems.length !== 0) {
                            const uploadImages = imageItems.filter((v: any) => v.file);
                            const uploadPromises = uploadImages.map(async (image: any) => {
                                try {
                                    setIsLoading(true);
                                    const uploadedImageUUID = await fileController.uploadFile(
                                        Number(userData?.id),
                                        image.file,
                                        values.type_product
                                    );
                                    setIsLoading(false);
                                    if (uploadedImageUUID.None) {
                                        throw new Error(`Error uploading image: ${image.name}`);
                                    }

                                    return {
                                        success: true,
                                        uuid: uploadedImageUUID.Some,
                                        name: image.file.name,
                                        updateUUid: image.uuid
                                    };
                                } catch (error) {
                                    // Handle error uploading file
                                    return {
                                        success: false,
                                        uuid: null,
                                        name: image.file.name,
                                        updateUUid: image.uuid
                                    };
                                }
                            });
                            // Wait for all upload promises to resolve
                            if (uploadPromises) {
                                uploadedImages = await Promise.all(uploadPromises);
                            }
                            const uploadImagesUrl = imageItems.filter((v: any) => v.file === null && v.uuid === 'url');
                            const uploadPromisesUrl = uploadImagesUrl.map(async (image: any) => {
                                const fileName = image.url.substring(image.url.lastIndexOf('/') + 1);
                                try {
                                    const uploadedImageUUID = await fileController.uploadFileUrl(
                                        Number(userData?.id),
                                        image.url,
                                        values.type_product
                                    );

                                    if (uploadedImageUUID.None) {
                                        throw new Error(`Error uploading image: ${image.name}`);
                                    }

                                    return {
                                        success: true,
                                        uuid: uploadedImageUUID.Some,
                                        name: fileName
                                    };
                                } catch (error) {
                                    // Handle error uploading file
                                    return {
                                        success: false,
                                        uuid: null,
                                        name: fileName
                                    };
                                }
                            });
                            if (uploadPromisesUrl) {
                                uploadedImagesUrl = await Promise.all(uploadPromisesUrl);
                            }
                        } else {
                            toast({
                                position: "top",
                                title: 'It is not possible to create a product without adding a photo',
                                status: 'error',
                                duration: 5000,
                                isClosable: true,
                            })
                            return;
                        }
                        // const hasFailedUploads = uploadedImages.some((uploadResult) => !uploadResult.success);
                        let outList = imageItems.filter((v: any) => v.file === null && v.uuid !== 'url')
                            .map((v: any) => {
                                return {uuid: v.uuid, name: "", updateUUid: ""}
                            })
                        if (uploadedImages.filter((uploadResult) => uploadResult.success).length > 0) {
                            let outList1 = uploadedImages.filter((uploadResult) => uploadResult.success).map((v: any) => {
                                return {uuid: v.uuid, name: v.name, updateUUid: v.updateUUid}
                            })
                            outList = outList.concat(outList1)
                        }
                        if (uploadedImagesUrl.filter((uploadResult) => uploadResult.success).length > 0) {
                            let outList1 = uploadedImagesUrl.filter((uploadResult) => uploadResult.success).map((v: any) => {
                                return {uuid: v.uuid, name: v.name, updateUUid: ""}
                            })
                            outList = outList.concat(outList1)
                        }
                        const filteredVideo = values.video.filter((videoItem: Content) => videoItem.value.trim() !== "");
                        const productRequest: ProductRequest = {
                            noTax: values.no_tax,
                            hiddenProduct: values.hidden_product,
                            id: values.id,
                            images: outList,
                            primaryImageUuid: (values.primaryImage < 0 || values.primaryImage >= outList.length) ? null : outList[values.primaryImage].uuid,
                            parentId: values.parent_id,
                            name: values.name,
                            url: values.url,
                            type: values.type_product,
                            price: values.price,
                            shippingCost: values.shipping_cost,
                            shippingLocal: values.shipping_local,
                            shippingStep: values.shipping_step,
                            shippingMinSize: values.shipping_min_size,
                            description: values.description,
                            orderBy: values.order_by,
                            descriptionCart: values.descriptionCart,
                            descriptionPacking: values.descriptionPacking,
                            quantityStep: values.quantityStep,
                            startValue: values.startValue,
                            startAdminValue: values.startAdminValue,
                            unitOfMeasurement: values.unitOfMeasurement,
                            video: filteredVideo,
                            discounts: values.commonContent,
                            archive: false,
                            packingSize: values.packingSize,
                            showCart: values.showCart,
                            outlinesInvoice: values.outlinesInvoice,
                            edit: edit
                        };
                        setIsLoading(true);
                        const res = await productController.createProduct(productRequest);

                        if (!res?.None) {
                            setIsLoading(false);
                            let successMessage;

                            if (!edit) {
                                successMessage = "Item created successfully " + res.Some + ". Do you want to return to the page where you started?";
                            } else {
                                successMessage = "Item updated successfully " + res.Some + ". Do you want to return to the page where you started?";
                            }
                            modalService.setModalData({
                                onSubmit: () => {
                                    modalService.deleteModalData();
                                    if (edit) {
                                        productController.getProduct(Number(id));
                                        dispatch({type: "PRODUCT_REMOVE"});
                                        imageService.clearImages();
                                    } else {
                                        navigate(`${URLPaths.PRODUCTS_EDIT.link}${res.Some}`)
                                    }
                                },
                                isOpen: true,
                                onOpen: console.log,
                                onClose: () => modalService.deleteModalData(),
                                modalTextData: successMessage,
                                context: "action"
                            });
                        } else {
                            setIsLoading(false);
                            modalService.setModalData({
                                onSubmit: () => modalService.deleteModalData(),
                                isOpen: true,
                                onOpen: () => {
                                },
                                onClose: () => modalService.deleteModalData(),
                                modalTextData: "Error editing param or creating product",
                                context: "error"
                            });
                        }
                        setIsLoading(false);
                    }}
                    validate={values => {
                        setInitialValues(values)
                    }}
                    enableReinitialize={true}
                    validationSchema={ProductValidator.GetSchema()}
                >

                    {({isSubmitting, errors, values, dirty, isValid, handleSubmit, setFieldValue}) => (
                        <FormControl {...FormStyleConfig}  >
                            <Form onSubmit={handleSubmit}>
                                <Flex maxW={{base: "100%", lg: "50%"}}>
                                    <Box {...BoxStyleConfig}>
                                        <Box {...BoxRowStyleConfig}>
                                            <LabelStyled minWidth={"100px"}>Param</LabelStyled>
                                            <Checkbox
                                                color="gray.400"
                                                isChecked={values.hidden_product}
                                                children={"Hidden product"}
                                                onChange={(ev: any) => setFieldValue("hidden_product", ev.target.checked, true)}
                                            />
                                            <Checkbox color="gray.400" pl={2}
                                                      isChecked={values.showCart}
                                                      children={"Show Cart"}
                                                      onChange={(ev: any) => {
                                                          setFieldValue("showCart", ev.target.checked, true)
                                                          setIsShowCart(ev.target.checked)
                                                      }}
                                            />
                                            {values.type_product === ProductTypes.PRODUCT &&
                                                <Checkbox color="gray.400" pl={2}
                                                          isChecked={values.outlinesInvoice}
                                                          children={"Outlines invoice"}
                                                          onChange={(ev: any) => {
                                                              setFieldValue("outlinesInvoice", ev.target.checked, true)
                                                          }}
                                                />
                                            }
                                        </Box>
                                        <Box {...BoxRowStyleConfig} py={1}>
                                            <LabelStyled minWidth={"100px"}>Name</LabelStyled>
                                            <InputControlStyled name="name" ml={"auto"}
                                                                inputProps={{maxW: "100%", minWidth: "100%"}}/>
                                        </Box>
                                        <Box {...BoxRowStyleConfig} pb={1}>
                                            <LabelStyled minWidth={"100px"}>URL</LabelStyled>
                                            <InputControlStyled name="url" ml={"auto"}
                                                                inputProps={{maxW: "100%", minWidth: "100%"}}/>
                                        </Box>
                                        {values.type_product !== ProductTypes.CATEGORY && (
                                            <>
                                                <Box {...BoxRowStyleConfig} pb={2}>
                                                    <LabelStyled minWidth={"100px"}
                                                                 htmlFor="description">Descr com</LabelStyled>
                                                    <TextareaControl
                                                        textareaProps={{fontSize: 14}}
                                                        name={"description"}
                                                        placeholder='Here is a sample description'
                                                        size='sm'
                                                    />
                                                </Box>

                                                {isShowCart && (
                                                    <Box {...BoxRowStyleConfig} pb={2} minH={"100%"}>
                                                        <LabelStyled minWidth={"100px"}
                                                                     htmlFor="descriptionCart">Descr cart
                                                        </LabelStyled>
                                                        <TextareaControl
                                                            textareaProps={{fontSize: 14, minHeight: "200px"}}
                                                            name={"descriptionCart"}
                                                            placeholder='Here is a sample description cart'

                                                        />
                                                    </Box>
                                                )}
                                                {values.video.length === 0 &&
                                                    <Box {...BoxRowStyleConfig}>
                                                        <LabelStyled minWidth={"100px"}> Added Video </LabelStyled>
                                                        <LinkVariantPlusIcon cursor="pointer" fontSize={"20px"}
                                                                             mt={"15px"}
                                                                             onClick={() => setFieldValue('video', [...values.video, {
                                                                                 id: 0,
                                                                                 value: '',
                                                                                 order: 1,
                                                                                 title: ''
                                                                             }])}/>
                                                    </Box>
                                                }

                                                {values.video.map((videoLink: Content, index: number) => (
                                                    <InputGroup pt={1} key={index} pl={"112px"}>
                                                        <InputControl
                                                            name={`video[${index}].title`}
                                                            inputProps={{type: "text", fontSize: 14}}
                                                            labelProps={{fontWeight: "md", fontSize: 14}}
                                                            label={index === 0 ? "Title" : undefined}
                                                        />
                                                        <InputControl
                                                            pl={1}
                                                            name={`video[${index}].value`}
                                                            inputProps={{type: "text", fontSize: 14}}
                                                            labelProps={{fontWeight: "md", fontSize: 14}}
                                                            label={index === 0 ? "Youtube Id" : undefined}
                                                            mr={"4rem"}
                                                        />
                                                        <InputRightElement
                                                            mt={index === 0 ? "2rem" : undefined}
                                                            mr={!(index === values.video.length - 1) || (values.video.length - 1 === 4) ? "2rem" : undefined}
                                                        >
                                                            <LinkVariantRemoveIcon cursor="pointer"
                                                                                   boxSize={5}
                                                                                   onClick={() => {
                                                                                       const newVideoLinks = [...values.video];
                                                                                       newVideoLinks.splice(index, 1);
                                                                                       setFieldValue('video', newVideoLinks);
                                                                                   }}/>
                                                        </InputRightElement>
                                                        {index === values.video.length - 1 && values.video.length < 5 && (
                                                            <InputRightElement
                                                                mt={index === 0 ? "2rem" : undefined}
                                                                mr={"2rem"}
                                                            >
                                                                <LinkVariantPlusIcon cursor="pointer"
                                                                                     boxSize={5}
                                                                                     onClick={() => setFieldValue('video', [...values.video, {
                                                                                         id: 0,
                                                                                         value: '',
                                                                                         order: 1,
                                                                                         title: ''
                                                                                     }])}/>
                                                            </InputRightElement>
                                                        )}
                                                    </InputGroup>
                                                ))}
                                                <NavBarLinkButton pl={32} pt={2} fontSize={14}
                                                                  onClick={() => navigate(`${URLPaths.PRODUCTS_EDIT.link}${values.parent_id}`)}
                                                                  text={'Go to parent product'}> </NavBarLinkButton>
                                            </>
                                        )}
                                        {(values.type_product === ProductTypes.PRODUCT
                                            || values.type_product === ProductTypes.CUSTOM
                                            || values.type_product === ProductTypes.REORDER
                                            || values.type_product === ProductTypes.OPTIONS
                                        ) && (
                                            <>
                                                {(productGet && productGet?.parentShippingCost > 0 && productGet?.shippingCost > 0) && (
                                                    <Text maxWidth={"60%"}
                                                          color={"lightcoral"}>{"Shipping installed in this product will not work since this product is covered by shipping in the parent component"}</Text>
                                                )
                                                }
                                                <Box {...BoxRowStyleConfig} pt={2}>
                                                    <LabelStyled minWidth={"100px"}>Price</LabelStyled>
                                                    <NumberInputControl
                                                        // maxW={"25%"}
                                                        labelProps={{fontSize: 14}}
                                                        name="price"
                                                    />
                                                    <Checkbox
                                                        pl={2}
                                                        minW={"25%"}
                                                        fontSize={14}
                                                        color="gray.400"
                                                        isChecked={values.no_tax}
                                                        children={"No tax"}
                                                        onChange={(ev: any) => setFieldValue("no_tax", ev.target.checked, true)}
                                                    />
                                                </Box>
                                                <InputGroup mt={"1rem"}>
                                                    <LabelStyled minWidth={"90px"}>Shipping</LabelStyled>
                                                    <NumberInputControl pl={3}
                                                                        labelProps={{
                                                                            fontWeight: "md",
                                                                            fontSize: 14
                                                                        }}
                                                                        name="shipping_cost"
                                                                        label={"Cost"}/>
                                                    <NumberInputControl pl={2}
                                                                        labelProps={{
                                                                            fontWeight: "md",
                                                                            fontSize: 14
                                                                        }}
                                                                        name="shipping_local"
                                                                        label={"Local"}/>
                                                    <NumberInputControl pl={2}
                                                                        labelProps={{
                                                                            fontWeight: "md",
                                                                            fontSize: 14
                                                                        }}

                                                                        name="shipping_step"
                                                                        label={"Step(%)"}/>
                                                    <NumberInputControl
                                                        defaultValue={1}
                                                        pl={2}
                                                        labelProps={{
                                                            fontWeight: "md",
                                                            fontSize: 14
                                                        }}
                                                        name="shipping_min_size"
                                                        label={"UOM"}/>

                                                </InputGroup>
                                                {values.type_product !== ProductTypes.OPTIONS &&
                                                    <InputGroup mt={"1rem"}>
                                                        <NumberInputControl pl={28}
                                                                            labelProps={{
                                                                                fontWeight: "md",
                                                                                fontSize: 14
                                                                            }}
                                                                            name="startAdminValue"
                                                                            label={"Start Admin"}/>
                                                        <NumberInputControl pl={1}
                                                                            labelProps={{
                                                                                fontWeight: "md",
                                                                                fontSize: 14
                                                                            }}
                                                                            name="startValue"
                                                                            label={"Start User"}/>
                                                        <NumberInputControl pl={1}
                                                                            labelProps={{
                                                                                fontWeight: "md",
                                                                                fontSize: 14
                                                                            }}
                                                                            name="quantityStep"
                                                                            label={"Step Value"}/>
                                                        <InputControl name="unitOfMeasurement" pl={1}
                                                                      labelProps={{
                                                                          fontWeight: "md",
                                                                          fontSize: 14
                                                                      }}
                                                                      label={"UOM"}/>
                                                    </InputGroup>
                                                }
                                            </>
                                        )}
                                        <InputGroup pt={2}>
                                            <SelectControlStyled pl={28}
                                                                 labelProps={{
                                                                     fontWeight: "md",
                                                                     fontSize: 14
                                                                 }}
                                                                 label={"Type"}
                                                                 name="type_product"
                                                                 maxWidth={"450px"}>
                                                {Object.entries(ProductTypes)
                                                    .filter(([key, value]) => (
                                                            (id === "0" || productGet?.parentId === 1000010) && editFilterProduct.home.types.includes(value)) ||
                                                        ((id !== "0" && productGet?.parentId !== 1000010) && editFilterProduct.product.types.includes(value)))
                                                    .map(([key, value]) => (
                                                        <option key={key} value={key}>
                                                            {value}
                                                        </option>
                                                    ))}
                                            </SelectControlStyled>
                                            <NumberInputControl pl={1}
                                                                labelProps={{
                                                                    fontWeight: "md",
                                                                    fontSize: 14
                                                                }}
                                                                name="order_by" label={"OrderBy"}/>
                                            <InputControl name="parent_id" pl={1}
                                                          labelProps={{
                                                              fontWeight: "md",
                                                              fontSize: 14
                                                          }}
                                                          inputProps={{type: "number"}}
                                                          label={"ParentId"}/>
                                        </InputGroup>
                                        <Box>
                                            <Flex pt={2}>
                                                <LabelStyled minWidth={"100px"}>Photo</LabelStyled>
                                                <PhotoUploadManager
                                                    item={edit && productGet?.id === Number(id) ? productGet : new ProductClass()}
                                                    primaryImage={values.primaryImage}
                                                />
                                            </Flex>
                                            {values.type_product === ProductTypes.PRODUCT &&
                                                <>
                                                    <ShowPriceCost contentDiscount={values.commonContent}/>
                                                    <Box mt="1rem"></Box>
                                                    <DiscountCost contentDiscount={values.commonContent}
                                                                  setFieldValue={setFieldValue}
                                                                  label={"Dealer discount (%)"}
                                                                  type={values.type_product}
                                                                  field='commonContent'
                                                                  dealerships={dealershipsMinInfo}/>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                </Flex>
                                <MainButton
                                    mt="20px"
                                    minWidth="120px"
                                    ml="auto"
                                    type="submit"
                                    isLoading={isSubmitting}
                                >
                                    Confirm
                                </MainButton>
                            </Form>
                        </FormControl>
                    )}
                </Formik>
            </>
        )
            ;
    }
;

export default NewProductComponent;
