import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Flex, useToast} from "@chakra-ui/react";
import AllowedAccess, {hasPermission} from "../../../features/Permission/AllowedAccess";
import {changePriceCreatePermission, changePriceRollbackPermission} from "../../permissions";
import IconWithTooltip from "../../../components/Icon/IconWithTooltip";
import {FiRotateCcw} from "react-icons/all";
import {ListProductItemConfig, ListProductItemRow} from "../../../components/List/ListProductItem";
import {Breadcrumbs} from "../../../components/Breadcrumbs/Breadcrumbs";
import {Loader} from "../../../components/Loader/Loader";
import {Paginator} from "../../../components/Paignator/Paginator";
import {ChangePrice, ReqChangePriceConfig} from "../../../shared/entities/Product/ChangePrice";
import {FieldType} from "../../../common/constants";
import {BreadcrumbsData} from "../../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {ModalService} from "../../../service/Modal/ModalService";
import {setBreadcrumbsLinks} from "../../../redux/Breadcrumbs/BreadcrumbsReducer";
import {FilterInterceptor} from "../../../shared/utilities/Filters/FilterInterceptor";
import {ChangePriceController} from "../../../controllers/ChangePriceController";
import ColumnHandler, {ColumnConfig} from "../../../components/Buttons/ColumnHandler";
import ChangePricePopover from "../../Modal/ChangePricePopover";

export const ChangePriceHistory: FC<{ links: Array<BreadcrumbsData> }> = ({links}) => {
        const dispatch = useDispatch();
        const toast = useToast();
        const [isLoading, setIsLoading] = useState(false)
        const modalService = new ModalService(dispatch)
        const [refresh, setRefresh] = useState(false);
        const [listAll, setListAll] = useState<any>({prices: [], count: 0})
        const changePriceController = new ChangePriceController(dispatch)
        setBreadcrumbsLinks(dispatch, links);

        const [currentPaginatorPosition, setCurrentPaginatorPosition] = useState(1)
        const [previewPaginatorPosition, setPreviewPaginatorPosition] = useState(1)
        const [filterInterceptor] = useState(new FilterInterceptor([]));
        const [filterConfig, setFilterConfig] = useState<any>()


        const handlePageChange = (newPage: number) => {
            setPreviewPaginatorPosition(currentPaginatorPosition);
            setCurrentPaginatorPosition(newPage);
        };


        useEffect(() => {
            setIsLoading(true)
            const reqConfig: ReqChangePriceConfig = {
                params: {
                    page: (currentPaginatorPosition === previewPaginatorPosition) ? 0 : (currentPaginatorPosition > 0) ? currentPaginatorPosition - 1 : 0,
                    size: 20
                },
                data: {}
            }
            setPreviewPaginatorPosition(currentPaginatorPosition);
            filterInterceptor.getFilterMap().forEach((filter, key) => {
                if (filter) {
                    reqConfig.data[key] = filter.value;
                }
            })
            changePriceController.getChangePriceAll(reqConfig).then(res => {
                setIsLoading(false)
                setListAll(res)
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filterConfig, currentPaginatorPosition, refresh])

        const columnsAll: Array<ColumnConfig> = [
            {
                header: "ID",
                filterField: "id",
                isLink: false,
                width: "10%",
                type: FieldType.Input
            }, {
                header: "DATE",
                filterField: "date",
                isLink: false,
                width: "15%",
                type: FieldType.Input
            }, {
                header: "TYPE",
                filterField: "type",
                isLink: false,
                width: "15%",
                type: FieldType.Select,
                options: [{id: "DEFAULT", name: ""}, {id: "SHIPPING", name: "Shipping"}, {id: "PRICE", name: "Price"}]
            },
            {
                header: "Value",
                filterField: "value",
                isLink: false,
                width: "55%",
                type: FieldType.Input
            },
            {header: "ACTIONS", filterField: "actions", isLink: false, width: "auto", type: FieldType.Input},
        ];

        const ColumnNamesMapping: Array<ListProductItemConfig> = columnsAll.map(
            (col: ColumnConfig, index: number) => {
                return {
                    content: col.header === "ACTIONS" || col.header === "ID" || col.header === "DATE" ? col.header : (
                        <ColumnHandler
                            col={col}
                            filterInterceptor={filterInterceptor}
                            setFilterConfig={setFilterConfig}
                        />
                    ),
                    width: columnsAll[index].width || "auto",
                    isLink: col.isLink || false,
                    style: col.style || {},
                };
            }
        );

        const confirmRemove = (id: number) => {
            modalService.setModalData({
                onSubmit: () => {
                    modalService.deleteModalData();
                    changePriceController.rollbackChangePrice({id: id}).then((res) => {
                        toast({
                            position: "top",
                            title: res.None
                                ? `Failed to remove ID: ${id}`
                                : `Change Price item rolled back and removed. ID: ${id}`,
                            status: res.None ? 'error' : 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        setRefresh(prevIndex => !prevIndex);
                    })
                },
                isOpen: true,
                onOpen: () => {
                },
                onClose: () => {
                    modalService.deleteModalData();
                },
                modalTextData: "Are you sure you want to remove and rollback the change price item?",
                context: "action"
            });
        };
        const List = listAll?.prices ? listAll.prices
            .map((elem: ChangePrice, idx: number) => {
                const ItemConfig: Array<ListProductItemConfig> = [
                    {
                        content: elem.id,
                        isLink: false,
                        width: columnsAll.find((col) => col.filterField === 'id')?.width || "auto",
                    },
                    {
                        content: elem.createdAt,
                        isLink: false,
                        width: columnsAll.find((col) => col.filterField === 'date')?.width || "auto",
                    },
                    {
                        content: elem.type,
                        isLink: false,
                        width: columnsAll.find((col) => col.filterField === 'type')?.width || "auto",
                    }, {
                        content: elem.value,
                        isLink: false,
                        width: columnsAll.find((col) => col.filterField === 'value')?.width || "auto",
                    },

                    hasPermission(changePriceRollbackPermission) && {
                        content: <IconWithTooltip icon={FiRotateCcw} tooltipMessage={'rollback and remove change price'}/>,
                        clickAction: () => confirmRemove(elem.id),
                        isLink: true,
                        width: columnsAll.find((col) => col.filterField === 'actions')?.width || "auto",
                    }
                ].filter(Boolean) as Array<ListProductItemConfig>;
                return <ListProductItemRow key={idx} list={ItemConfig}
                                           style={{fontSize: "13px", minHeight: "35px", justifyContent: "flex-start",}}/>
            }) : null;

        return (
            <>
                <Breadcrumbs/>
                <Flex flexDir="column" height="100%">
                    <Flex justifyContent={"flex-end"}>
                        <AllowedAccess permissionKey={changePriceCreatePermission}>
                            <ChangePricePopover onSuccess={() => setRefresh(prevIndex => !prevIndex)}/>
                        </AllowedAccess>
                    </Flex>
                    <Flex flexDir="row" mb="10px" justifyContent="space-between" alignItems="end">
                        <Flex
                            flexDir="row"
                            alignItems="end"
                            width="80%"
                            fontFamily="Poppins"
                            position="relative"
                            flexWrap="wrap">
                            {filterInterceptor.getFiltersRowWithCreateButton()}
                        </Flex>
                    </Flex>
                    {
                        isLoading
                            ? <Loader/>
                            : <>
                                <ListProductItemRow list={ColumnNamesMapping} style={{
                                    fontSize: "13px",
                                    fontWeight: "700",
                                    backgroundColor: "aliceblue",
                                    justifyContent: "flex-start"
                                }}/>
                                {List}
                            </>
                    }
                </Flex>
                <Paginator limit={typeof listAll?.count === 'number' ? listAll?.count : 0}
                           current={currentPaginatorPosition}
                           swapPageTrigger={handlePageChange} step={20}/>
            </>
        )
    }
;

export default ChangePriceHistory;