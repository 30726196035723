export const clientsKey: string = 'Clients';
export const ordersKey: string = 'Orders';
export const productsKey: string = 'Products';
export const rolesKey: string = 'Roles';
export const administratorsKey: string = 'Administrators';
export const settingsKey: string = 'Settings';
export const subscribeExcludeKey: string = 'Subscribe_Exclude';
export const changePriceKey: string = 'Change_Price';
export const dealershipExcludeKey: string = 'Dealership_Exclude';
export const accountKey: string = 'Account';
