import {
    Box,
    Flex,
    Text,
    ChakraProps,
    Link,
    Select,
    Tooltip,
    Collapse,
    useDisclosure, useBreakpointValue
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import {useAuth} from "../../hooks/AuthHook";
import {URLPaths} from "../../config/application/URLPaths";
import PopoverCart from "../../views/CartBasket/PopoverCart";
import {useAppSelector} from "../../redux/Store";
import {BoxRowStyleConfig, BoxStyleConfig} from "../../views/Forms/FormStyleConfigs";
import {ChevronDownIcon, Icon} from "@chakra-ui/icons";
import {UserController} from "../../controllers/UserController";
import {Dealership} from "../../shared/entities/Users/Dealership";
import {generalColor, generalView} from "../../theme/foundations/breakpoints";
import {
    BannerIcon,
    BannerIconMobile,
    CallIcon,
    LogoMobile,
    Logo
} from "../Inputs/Icons/Icons";
import {StateController} from "../../controllers/StateController";
import NavBarLinkButton from "../Link/NavBarLinkButton";
import Cart from "../../views/CartBasket/Cart";
import CartButton from "../../views/CartBasket/CartButton";
import MenuLinks from "../../views/Menu/Menu";
import MenuButton from "../../views/Menu/MenuButton";
import {AuthorizationController} from "../../controllers/AuthController";
import SearchWithHistory from "../Search/SearchWithHistory";
import {DownloadButton} from "../Buttons/DownloadButton";
import {DownloaderInstance} from "../../service/Downloader/Downloder";
import {
    accountPermission,
    adminViewPermission, changePriceViewPermission,
    clientViewPermission, dealershipExcludeSelectPermission, orderEditPermission, productEditPermission,
    roleViewPermission
} from "../../views/permissions";
import AllowedAccess, {hasPermission} from "../../features/Permission/AllowedAccess";

export const Navbar: FC = () => {
    const {
        isOpen: isMenuOpen,
        onToggle: onMenuToggle,
        onClose: onMenuClose,
    } = useDisclosure();
    const {
        isOpen: isCartOpen,
        onToggle: onCartToggle,
        onClose: onCartClose
    } = useDisclosure();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userController = new UserController(dispatch);
    const NavbarStyleConfig: ChakraProps = {
        pt: {base: 2, lg: 10},
    };
    const stateController = new StateController(dispatch)
    const location = useLocation();
    const authDealership = useAppSelector(state => state.AuthReducer.dealership);
    const [newUserCount, setNewUserCount] = useState(0);
    const authController = new AuthorizationController(dispatch)

    const [dealerships, setDealerships] = useState<Dealership[]>([]);
    const {isAuth, handleAuthChange} = useAuth()

    const products = useAppSelector((state) => state.ProductReducer.productList);
    const orders = useAppSelector((state) => state.OrderReducer.orderClientList);
    const users = useAppSelector((state) => state.UserReducer.companyAll);
    const user = useAppSelector(store => store.AuthReducer.user);
    let isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false, xl: false, "2xl": false})
    const LogOut = () => {
        authController.logOut().then(() => {
            sessionStorage.clear();
            handleAuthChange(false);
            dispatch({type: "LOG_OUT"});
            window.location.reload();
        });
        navigate(URLPaths.HOME.link);
    };
    useEffect(() => {
        if (user) {
            if (user.dealerships) {
                if (user.dealerships.length === 0 || hasPermission(dealershipExcludeSelectPermission)) {
                    dispatch({type: "SET_DEALERSHIP", payload: {}});
                }
                if (user.dealerships.length === 1 && !hasPermission(dealershipExcludeSelectPermission)) {
                    sessionStorage.setItem("DEALERSHIP", user.dealerships[0]?.name);
                    if (user.dealerships[0].id) {
                        setVal(user.dealerships[0].id.toString())
                        dispatch({type: "SET_DEALERSHIP", payload: user.dealerships[0]});
                        setDealerships(user.dealerships);
                        return;
                    }
                }
                let dealer = user.dealerships.find(value => value.name === sessionStorage.getItem("DEALERSHIP"))
                if (dealer && dealer.id !== undefined) {
                    dispatch({type: "SET_DEALERSHIP", payload: dealer});
                    setBlink(true);
                    setVal(dealer.id.toString());
                }
                setDealerships(() => {
                    const isExist = user.dealerships.some(item => item.id === 0);
                    if (isExist) {
                        return user.dealerships;
                    } else {
                        return [...user.dealerships, {name: "Choose a Dealer", phone: "", id: 0}];
                    }
                });
            }

            if (hasPermission(orderEditPermission) || hasPermission(productEditPermission)) {
                userController.getDealershipMinInfoAll({}).then(() => {
                })
            }
        }
    }, [user]);

    useEffect(() => {
        stateController.getStates().then(() => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ((products || orders || users) && hasPermission(adminViewPermission)) {
            userController.getUserList().then(res => {
                setNewUserCount(res.Some?.length)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, orders, users]);

    const [val, setVal] = useState<string>("0");
    const [blink, setBlink] = useState(false);

    const updateDealership = (value: string) => {
        setVal(value)
        const dealer = dealerships.find((elem) => elem?.id === Number(value));
        if (dealer) {
            setBlink(true);
            sessionStorage.setItem("DEALERSHIP", dealer?.name || "")
            dispatch({type: "SET_DEALERSHIP", payload: dealer});
        }
    }

    const handleLinkHome = () => {
        navigate(URLPaths.HOME.link);
    };
    const downloadPriceFunc = async () => {
        return await DownloaderInstance.getProductPrice(null);
    }

    return (
        <Flex {...NavbarStyleConfig} flexDir="column">
            {isMobile ?
                <Box>
                    <Flex alignItems="center">
                        <Flex ml={isAuth ? "auto" : "unset"} justifyContent={isAuth ? "unset" : "space-between"}
                              alignItems="center"
                              w={isAuth ? "unset" : "100%"}>
                            <MenuButton onClick={() => {
                                if (!location.pathname.includes(URLPaths.AUTH.link) && !isAuth) {
                                    navigate(URLPaths.AUTH.link + location.pathname);
                                } else {
                                    onMenuToggle();
                                    onCartClose();
                                }
                            }}/>
                            <CartButton onClick={() => {
                                onCartToggle();
                                onMenuClose();
                            }}/>
                        </Flex>
                    </Flex>
                    {isAuth && dealerships.length > 2 && !hasPermission(dealershipExcludeSelectPermission) && (
                        <Box pt={2}>
                            <Tooltip
                                label={authDealership === undefined ? "no selected" : authDealership?.name}
                            >
                                <Select
                                    icon={<ChevronDownIcon fontSize={24}/>}
                                    borderColor={blink ? "green" : "red"}
                                    value={val}
                                    fontSize={14}
                                    width="100%"
                                    onChange={(ev) => {
                                        updateDealership(ev.target.value);
                                        ev.target.blur();
                                    }}
                                >
                                    {dealerships?.map((elem) => (
                                        <option key={elem.id} value={elem.id} hidden={elem.id === 0}>
                                            {elem.name}
                                        </option>
                                    ))}
                                </Select>
                            </Tooltip>
                        </Box>
                    )}
                    {isAuth &&
                        <Collapse in={isMenuOpen} animateOpacity>
                            <Box py={2}>
                                <Box p={4} style={{
                                    background: "#FFFFFF",
                                    borderRadius: "5px",
                                    border: "1.5px solid #6B7280",
                                    boxShadow: "0px 4px 32px #B3B2B2",
                                }}>
                                    <MenuLinks/>
                                </Box>
                            </Box>
                        </Collapse>
                    }
                    <Collapse in={isCartOpen} animateOpacity>
                        <Box py={2}>
                            <Box p={4} style={{
                                background: "#FFFFFF",
                                borderRadius: "5px",
                                border: "1.5px solid #6B7280",
                                boxShadow: "0px 4px 32px #B3B2B2",
                            }}>
                                <Cart onCheckout={onCartClose}/>
                            </Box>
                        </Box>
                    </Collapse>
                    <Flex pt={4}>
                        <Icon as={LogoMobile} onClick={() => handleLinkHome()}
                              py={1}
                              width={["50%"]} // Adjust sizes for different breakpoints
                              height={["50%"]} // Adjust sizes for different breakpoints
                              cursor="pointer"
                        />
                        <Icon as={BannerIconMobile} onClick={() => navigate(URLPaths.GREAT_SAVE.link)}
                              pl={2}
                              width={["50%"]} // Adjust sizes for different breakpoints
                              height={["50%"]} // Adjust sizes for different breakpoints
                              cursor="pointer"
                              color="gray.200"
                              _hover={{color: "gray.500"}}
                              transition="all 0.2s ease-in-out" // Smooth transition for the hover effect
                        />
                    </Flex>
                    <Flex w={"100%"} flexDir={isAuth ? "row" : "column"}>
                        {(location.pathname.includes(URLPaths.PRODUCTS.link) || location.pathname === URLPaths.HOME.link) && (
                            <SearchWithHistory width="100%" isMobile={isMobile}/>
                        )}
                        {isAuth &&
                            <Flex pt={2} pl={2} justifyContent="flex-end" ml={"auto"}>
                                <NavBarLinkButton
                                    fontSize={14}
                                    fontWeight="sm"
                                    text="Orders"
                                    onClick={() => navigate(URLPaths.ORDER_HISTORY.link)}
                                />
                            </Flex>
                        }
                    </Flex>
                    <Box py={2}>
                        <Flex alignItems="center" color={generalColor.primaryHeading} fontWeight={600} pb={2}>
                            <Icon as={CallIcon}/>
                            <Link href="tel:8002605051" _hover={{textDecoration: "underline"}}>
                                <Text paddingLeft={2} fontFamily="Poppins">800.260.5051</Text>
                            </Link>
                        </Flex>
                        <Link color={generalColor.primaryText}
                              href="mailto:info@akdealerservices.com" _hover={{textDecoration: "underline"}}>
                            info@akdealerservices.com
                        </Link>
                    </Box>
                </Box>
                :
                <Flex width={"100%"} direction={{base: "column", lg: "row"}} pb={isAuth ? 0 : 4}>
                    <Box {...BoxStyleConfig} maxWidth={{base: "100%", md: "80%", lg: "31%"}}>
                        <Box {...BoxRowStyleConfig} paddingBottom={2}>
                            <Icon as={Logo} onClick={() => handleLinkHome()}
                                  py={1}
                                  width={["100%"]}
                                  height={["100%"]}
                                  maxW={generalView.logo.width}
                                  cursor="pointer"
                            />
                        </Box>
                        <Flex direction={{base: "column", "2xl": "row"}}>
                            <Flex alignItems="center" color={generalColor.primaryHeading} fontWeight={600}
                                  paddingRight={2}>
                                <Icon as={CallIcon}/>
                                <Text paddingLeft={2} fontFamily="Poppins">800.260.5051</Text>
                            </Flex>
                            <Box backgroundColor={"gray.200"} width="1px"/>
                            <Link color={generalColor.primaryText} fontFamily="Poppins" fontWeight={300}
                                  href="mailto:info@akdealerservices.com" _hover={{textDecoration: "underline"}}
                                  paddingLeft={2}>
                                info@akdealerservices.com
                            </Link>
                        </Flex>

                    </Box>
                    {!isAuth &&
                        <Box {...BoxStyleConfig} >
                            <Icon as={BannerIcon} onClick={() => navigate(URLPaths.GREAT_SAVE.link)} width="300px"
                                  p={"1px"}
                                  height={"100%"} cursor={"pointer"}
                                  color="gray.200"
                                  _hover={{color: "gray.500"}}
                            />
                        </Box>
                    }

                    <Box {...BoxStyleConfig} >
                        <Flex fontSize="14px" direction={{base: "column", lg: "row"}} justifyContent="flex-end">
                            {/* Пустой Box с flexGrow для вытеснения остальных элементов вправо */}
                            <Box flexGrow={1}></Box>
                            <Flex minHeight="40px">
                                {/*<PopoverMenu/>*/}
                                {isAuth ?
                                    <Flex h="100%" alignItems="center">
                                        <Text pr={3} fontSize={14} fontWeight={"md"}>{user?.login}</Text>
                                    </Flex>
                                    :
                                    <MenuButton onClick={() => {
                                        if (!location.pathname.includes(URLPaths.AUTH.link)) {
                                            navigate(URLPaths.AUTH.link + location.pathname);
                                        }
                                    }}/>
                                }
                                {isAuth && dealerships.length > 2 && !hasPermission(dealershipExcludeSelectPermission) && (
                                    <Box pr={3}>
                                        <Tooltip
                                            label={authDealership === undefined ? "no selected" : authDealership?.name}>
                                            <Select
                                                icon={<ChevronDownIcon fontSize={24}/>}
                                                borderColor={blink ? "green" : "red"}
                                                value={val}
                                                fontSize={14}
                                                w={200}
                                                pa={5}
                                                onChange={(ev) => {
                                                    updateDealership(ev.target.value);
                                                    ev.target.blur();
                                                }}
                                            >
                                                {dealerships?.map((elem) => (
                                                    <option key={elem.id} value={elem.id} hidden={elem.id === 0}>
                                                        {elem.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Flex>
                            <PopoverCart/>
                            {/*<CartBasketDrawer/>*/}
                            {(location.pathname.includes(URLPaths.PRODUCTS.link) || location.pathname === URLPaths.HOME.link || location.pathname === URLPaths.CART.link) && (
                                <Box paddingLeft={{base: 0, lg: 4}} ml={{lg: "auto"}} mr={{lg: "auto"}}
                                     pt={{base: 2, lg: 0}}>
                                    <SearchWithHistory/>
                                </Box>
                            )}
                        </Flex>
                        {isAuth &&
                            <Flex ml={"auto"} flexDir={"column"}>
                                <Flex ml={"auto"} pt={1}>
                                    <NavBarLinkButton fontSize={14} fontWeight={"sm"}
                                                      text={'Orders'}
                                                      onClick={() => navigate(URLPaths.ORDER_HISTORY.link)}/>
                                    <AllowedAccess permissionKey={clientViewPermission}>
                                        <Box backgroundColor={"gray.200"} width="1px"/>
                                        <NavBarLinkButton pl={1} fontSize={14} fontWeight={"sm"}
                                                          text={'Company list'}
                                                          onClick={() => navigate(URLPaths.COMPANIES.link)}/>
                                        {newUserCount !== 0 &&
                                            <Flex color={'white'} background={'red'} borderRadius={10} boxSize={5}
                                                  alignItems='center' justifyContent={"center"}
                                                  fontSize={14}>{newUserCount}</Flex>
                                        }
                                    </AllowedAccess>
                                </Flex>
                                <Flex fontSize={14} ml={"auto"} pt={1}>
                                    <AllowedAccess permissionKey={accountPermission}>
                                        <NavBarLinkButton fontSize={14} fontWeight={"sm"}
                                                          text={'Account'}
                                                          onClick={() => navigate(URLPaths.ACCOUNT.link)}/>
                                        <Box backgroundColor={"gray.200"} width="1px"/>
                                    </AllowedAccess>
                                    <AllowedAccess permissionKey={roleViewPermission}>
                                        <NavBarLinkButton fontSize={14} fontWeight={"sm"} pl={1}
                                                          text={'Role list'}
                                                          onClick={() => navigate(URLPaths.ROLES.link)}/>
                                        <Box backgroundColor={"gray.200"} width="1px"/>
                                    </AllowedAccess>
                                    <NavBarLinkButton fontSize={14} fontWeight={"sm"} pl={1}
                                                      text={'Log Out'}
                                                      onClick={LogOut}/>
                                </Flex>
                                <Flex>
                                    <AllowedAccess permissionKey={clientViewPermission}>
                                        <Flex fontSize={14} ml={"auto"} pt={1}>
                                            <DownloadButton clickAction={() => downloadPriceFunc()}
                                                            label={"Price list"}
                                                            nameFile={"price-list.xlsx"}/>
                                        </Flex>
                                    </AllowedAccess>
                                    <AllowedAccess permissionKey={changePriceViewPermission}>
                                        <Box backgroundColor={"gray.200"} width="1px"/>
                                        <Flex fontSize={14} ml={"auto"} pt={1}>
                                            <NavBarLinkButton fontSize={14} fontWeight={"sm"} pl={1}
                                                              text={'Change Price'}
                                                              onClick={() => navigate(URLPaths.CHANGE_PRICE.link)}/>
                                        </Flex>
                                    </AllowedAccess>
                                </Flex>
                                <AllowedAccess permissionKey={accountPermission} localPermission={Number(val) !== 0}>
                                    <Flex fontSize={14} ml={"auto"} pt={1}>
                                        <NavBarLinkButton fontSize={14} fontWeight={"sm"} pl={1}
                                                          text={'All Items Purchased'}
                                                          onClick={() => navigate(`${URLPaths.ALL_PRODUCTS_ORDER.link}`)}/>
                                    </Flex>
                                </AllowedAccess>
                            </Flex>
                        }
                    </Box>
                </Flex>
            }
        </Flex>
    );
};
