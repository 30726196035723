import React, {FC, useEffect, useState} from "react";
import {
    Box,
    Button,
    ChakraProps,
    Flex,
    Text, useBreakpointValue,
} from "@chakra-ui/react";
import {Product} from "../../shared/entities/Product/Product";
import {useNavigate} from "react-router";
import {MainButton} from "../../components/Buttons/MainButton";
import ImageCustom from "../../components/Image/ImageCustom";
import {ProductCostCalculateUtils} from "../../shared/utilities/ProductCostCalculateUtils";
import DiscountComponent from "../Products/Discount/DiscountComponent";
import {useAppSelector} from "../../redux/Store";
import {URLPaths} from "../../config/application/URLPaths";
import {CartService} from "../../service/Cart/CartService";
import {useDispatch} from "react-redux";
import {hasPermission} from "../../features/Permission/AllowedAccess";
import {orderEditPermission} from "../permissions";

interface CartProps {
    onCheckout: () => void;
}

const lineStyle: ChakraProps = {
    borderBottom: "1px solid #B3B2B2",
    width: "100%",
};

const Cart: FC<CartProps> = ({onCheckout}) => {
    const [productList, setProductList] = useState<Array<Product>>([]);
    const [showAllItems, setShowAllItems] = useState(false);
    const cartItems = useAppSelector((state) => state.CartReducer.cartItems);
    const subTotal = useAppSelector((state) => state.CartReducer.subTotal);
    const navigate = useNavigate();
    const authDealership = useAppSelector((state) => state.AuthReducer.dealership);
    let isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false, xl: false, "2xl": false})
    const dispatch = useDispatch();
    const cartService = new CartService(dispatch)
    const selDealership = useAppSelector(state => state.UserReducer.dealership);
    const currentDealer = hasPermission(orderEditPermission) ? selDealership : authDealership;
    useEffect(() => {
        if(currentDealer) {
            cartService.updateDealershipCart(currentDealer);
        }
        const updatedProductList: Array<Product> = [];
        for (const elem of cartItems) {
            if (updatedProductList.length >= 3 && !showAllItems && !isMobile) {
                break;
            }
            updatedProductList.push({...elem});
        }
        setProductList(updatedProductList);
    }, [cartItems, showAllItems, isMobile, currentDealer]);


    return (
        <>
            {cartItems.length === 0 ? (
                <Text>Cart empty</Text>
            ) : (
                <Box overscrollY={"auto"}>
                    {productList.map((product) => (
                        <React.Fragment key={product.id}>
                            <Flex key={product.id} alignItems="center" justifyContent="space-between" py={1}>
                                <Flex>
                                    <Box minWidth={"16%"}>
                                        <ImageCustom elem={product} sizeInPercent={100} currentSize={"small"}
                                                     sizeBackend={"COMMON"} showOnHover={false}/>
                                    </Box>
                                    <Text pl={4} fontSize="14px" fontWeight={"sm"}>
                                        {product.name}
                                    </Text>
                                </Flex>
                                <Flex pl={1}>
                                    {!isMobile &&
                                        <DiscountComponent
                                            fontSizePrice={"14px"}
                                            elem={product}
                                            hiddenUOM={true}
                                            showDiscount={true}
                                            selectedDealer={currentDealer ? currentDealer : undefined}
                                            cart={true}
                                        />
                                    }
                                    <Text fontWeight={"md"}>
                                        $
                                        {Number(
                                            (
                                                ProductCostCalculateUtils.calculatePrice(
                                                    product,
                                                    currentDealer ? currentDealer : undefined
                                                ) * product.quantity
                                            ).toFixed(2)
                                        )}
                                    </Text>
                                </Flex>
                            </Flex>
                            <Box {...lineStyle}></Box>
                        </React.Fragment>
                    ))}
                    <Flex flexDirection="column">
                        <Flex alignItems="center" justifyContent="space-between" pb={4} pt={1}>
                            {cartItems.length > 3 && !showAllItems && !isMobile && (
                                <Flex justifyContent="center">
                                    <Button
                                        variant="link"
                                        fontSize="14px"
                                        onClick={() => setShowAllItems(true)}
                                    >
                                        +{cartItems.length - 3} More Items
                                    </Button>
                                </Flex>
                            )}
                            <Text ml={"auto"} fontWeight={"md"}>
                                Subtotal: ${subTotal.toFixed(2)}
                            </Text>
                        </Flex>

                        <MainButton ml="auto" minH={"30px"} pr={5}
                                    onClick={() => {
                                        navigate(URLPaths.CART.link)
                                        onCheckout();
                                    }}>
                            Edit Cart and Checkout
                        </MainButton>
                    </Flex>
                </Box>
            )}
        </>
    );
};

export default Cart;
