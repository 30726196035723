import {OrderProduct} from "../OrderProduct/OrderProduct";
import {ContentTypes, Product} from "../Product/Product";

export type Order = {
    id: number;
    tax: number;
    shippingCost: number;
    totalAmount: number;
    sendCount: number;
    printPressed: number;
    shippingStatus: number;
    paymentStatus: OrderTypes;
    shippingComment: string | null;
    paymentComment: string | null;
    createdAt: number;
    poNumber: string | null;
    namePo: string | null;
    products: Array<OrderProduct>;

    billingAddressId: number;
    shippingAddressId: number;
    user: OrderUser | null;
    dealership: OrderDealership | null;
};

export type OrderUser = {
    id: number;
    name: string;
    email: string;
    isAdmin: boolean;
};

export type OrderDealership = {
    id: number;
    companyId: number;
    name: string;
    email: string;
    localStatus: boolean;
};

export enum OrderTypes {
    IN = 0,
    PENDING = 1,
    PAID = 2
};

export interface ReqOrderConfig {
    params: {
        page: number;
        size: number;
        state?: OrderTypes;
    };
    data: {
        [key: string]: any
    };
}

export interface CheckBasketResponseDTO {
    items: Product[]; // Ссылка на интерфейс ProductDTO
    dealershipId: number;
    outOffDateProduct: Record<number, CompareObject[]>; // HashMap заменяется на Record
    outOfDateDiscount: Record<number, CompareDiscountObject[]>; // HashMap заменяется на Record
}

export interface CompareObject {
    field: string;
    current: string;
    outOffDate: string;
}

export interface CompareDiscountObject {
    discountType: ContentTypes;
    current: string;
    outOffDate: string;
}