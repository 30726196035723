import React, {useEffect, useState, useRef, FC} from 'react';
import {Box} from "@chakra-ui/react";
import {OrderController} from "../../controllers/OrderController";
import {useDispatch} from "react-redux";
import {ModalService} from "../../service/Modal/ModalService";
import {DocumentTypes} from "../../common/constants";

const PDFViewer: FC<{ isPrint?: boolean, orderIds: Array<any>, reqFilter: any, isDocumentTypes: DocumentTypes }> = ({
                                                                                                                        isPrint = false,
                                                                                                                        orderIds,
                                                                                                                        reqFilter,
                                                                                                                        isDocumentTypes
                                                                                                                    }) => {
    const dispatch = useDispatch();
    const orderController = new OrderController(dispatch);
    const modalService = new ModalService(dispatch);
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (isDocumentTypes === DocumentTypes.Invoice) {
            orderController.invoicePdf(reqFilter).then(res => {
                if (res.None) {
                    modalService.setModalData({
                        onSubmit: () => modalService.deleteModalData(),
                        isOpen: true,
                        onOpen: () => {
                        },
                        onClose: () => modalService.deleteModalData(),
                        modalTextData: "Data not found",
                        context: "error"
                    });
                } else {
                    const blob = new Blob([res.Some], {type: 'application/pdf'});
                    setPdfUrl(URL.createObjectURL(blob));
                }
            });
        } else if (isDocumentTypes === DocumentTypes.PackingSleep) {
            orderController.packingSleep(reqFilter).then(res => {
                if (res.None) {
                    modalService.setModalData({
                        onSubmit: () => modalService.deleteModalData(),
                        isOpen: true,
                        onOpen: () => {
                        },
                        onClose: () => modalService.deleteModalData(),
                        modalTextData: "Data not found",
                        context: "error"
                    });
                } else {
                    const blob = new Blob([res.Some], {type: 'application/pdf'});
                    setPdfUrl(URL.createObjectURL(blob));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderIds]);

    useEffect(() => {
        if (pdfUrl && iframeRef.current && iframeRef.current.contentWindow && isPrint) {
            setTimeout(() => {
                const iframeWindow = iframeRef.current!.contentWindow; // Добавлен оператор !
                if (iframeWindow) {
                    iframeWindow.print();
                }
            }, 100);
        }
    }, [pdfUrl, isPrint]);

    return (
        <Box>

            {pdfUrl && (
                <iframe
                    ref={iframeRef}
                    title="PDF Viewer"
                    src={pdfUrl}
                    width="100%"
                    height="1000px"
                ></iframe>
            )}
        </Box>
    );
};

export default PDFViewer;
