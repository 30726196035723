import {MapLike} from "typescript";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";


/**
 * Маппинги названий секций и их путей
 * используются в роутинге
 */
export const URLPaths: MapLike<BreadcrumbsData> = {
    HOME: {
        link: "/",
        text: "Home"
    },
    HOME_ARCHIVE: {
        link: "/archive/",
        text: "Archive products"
    },
    CONTACTS: {
        link: "/contacts",
        text: "Contacts"
    },
    ABOUT_US: {
        link: "/about_us",
        text: "About us"
    },
    GREAT_SAVE: {
        link: "/great_save",
        text: "Great save"
    },
    ORDER_BY_CATALOG: {
        link: "/order-by-catalog",
        text: "Order By Catalog"
    },
    CART: {
        link: "/cart",
        text: "Cart"
    },
    ORDER_VIEW: {
        link: "/order/view/",
        text: "Order View"
    },
    ALL_PRODUCTS_ORDER: {
        link: "/order-products-all/",
        text: "Order product view"
    },
    ORDER_UNREGISTERED: {
        link: "/order/unregistered/",
        text: "Order Unregistered"
    },
    ORDER_HISTORY: {
        link: "/orders",
        text: "Orders"
    },
    ORDER_HISTORY_USER: {
        link: "/orders/user",
        text: "Orders"
    },
    INVOICE: {
        link: "/invoice/",
        text: "Invoice"
    },

    AUTH: {
        link: "/auth",
        text: "Log In"
    },
    ACCOUNT: {
        link: "/account",
        text: "Account"
    },
    ACCOUNT_REGISTRATION: {
        link: "/account/registration",
        text: "Registration"
    },
    CHANGE_PASSWORD: {
        link: "/change/password",
        text: "Change password"
    },
    PASSWORD_RESTORE_CODE: {
        link: "/password-restore",
        text: "Passowrd restore"
    },
    PASSWORD_CREATE: {
        link: "/password-create",
        text: "Passowrd create"
    },
    RESTORE_PASSWORD: {
        link: "/restore/password",
        text: "Restore password"
    },
    COMPANY_VIEW: {
        link: "/company/view/",
        text: "View company"
    },
    COMPANY_EDIT: {
        link: "/company/edit/",
        text: "Edit company"
    },
    COMPANY_CREATE: {
        link: "/company/create/",
        text: "Create company"
    },
    COMPANIES: {
        link: "/companies/",
        text: "Companies"
    },
    ROLES: {
        link: "/roles",
        text: "Roles"
    },
    ROLES_CREATE: {
        link: "/accounts/roles/create",
        text: "Create role"
    },
    ROLES_VIEW: {
        link: "/accounts/roles/view/",
        text: "View role"
    },
    ROLES_EDIT: {
        link: "/accounts/roles/edit/",
        text: "Edit role"
    },
    PRODUCTS: {
        link: "/product/",
        text: "Service Supplies"
    },
    PRODUCTS_CREATE: {
        link: "/product/create/",
        text: "Create product card"
    },
    PRODUCTS_EDIT: {
        link: "/product/edit/",
        text: "Edit product card"
    },
    PRODUCTS_ARCHIVE: {
        link: "/archive/product/",
        text: "View product card"
    },
    SUBSCRIBE_EXCLUDE: {
        link: "/subscribe_exclude",
        text: "Subscribe exclude mail"
    },
    CHANGE_PRICE: {
        link: "/change_price",
        text: "Change price"
    },
}

