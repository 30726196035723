import {PayloadAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {ChangePriceService} from "../service/Product/ChangePriceService";

export class ChangePriceController {
    private dispatch: Dispatch<PayloadAction<any>>
    private service: ChangePriceService

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
        this.service = new ChangePriceService(this.dispatch)
    }


    public async getChangePriceAll(data?: any) {
        return this.service.getChangePriceAll(data).then(res => {
            return res
        })
    }

    public async rollbackChangePrice(params: any) {
        return this.service.rollbackChangePrice(params).then(res => {
            return res
        })
    }

    public async addChangePrice(data: any) {
        return this.service.addChangePrice(data).then(res => {
            return res
        })
    }
}
