import {Box, ChakraProps} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {AuthorizationController} from "./controllers/AuthController";
import {useAuth} from "./hooks/AuthHook";
import {useAppSelector} from "./redux/Store";
import {MainContainer} from "./views/MainContainer";
import {CustomModal} from "./views/Modal/CustomModal";
import YouTubeModal from "./views/Modal/YouTubeModal";
import AddProductModal from "./views/Modal/AddProductModal";

// https://react-icons.github.io/react-icons/icons/fi/
/**
 * App.
 *
 * App's main starting poing
 *
 * Renders header and app's main workspace
 * @see Header
 * @see MainContainer
 *
 */


function App() {
    const dispatch = useDispatch()
    const modalData = useAppSelector(store => store.ModalReducer.data)
    const {isAuth, handleAuthChange} = useAuth()
    const authController = new AuthorizationController(dispatch)
    const containerStyleConfig: ChakraProps = {}

    useEffect(() => {
        if (isAuth === null || isAuth === undefined) {
            authController.getPublicKey()
            authController.checkIfAuthorized().then(res => {
                if (res.None) {
                    authController.refresh().then(() => {
                            authController.checkIfAuthorized().then(res => {
                                handleAuthChange(res.Some)
                            });
                        return;
                    }).catch(() => {
                        handleAuthChange(false)
                    });
                }
                handleAuthChange(res.Some)
            }).catch(() => {
                handleAuthChange(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth])

    return (
        <Box className="App" {...containerStyleConfig} >
            <MainContainer isAuth={isAuth}/>
            {modalData && <CustomModal {...modalData} />}
            <YouTubeModal/>
            <AddProductModal/>
            <></>
        </Box>
    );
}

export default App;
