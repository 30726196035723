import React, {useState} from "react";
import {
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    useToast, Text, FormControl, FormLabel, Select, Input, Flex, useDisclosure,
} from "@chakra-ui/react";

import {useDispatch} from "react-redux";
import {ChangePriceController} from "../../controllers/ChangePriceController";
import {ChangePriceAddedRequest, ChangePriceTypes} from "../../shared/entities/Product/ChangePrice";
import {MainButton} from "../../components/Buttons/MainButton";
interface ChangePricePopoverProps {
    onSuccess?: () => void;
}

const ChangePricePopover: React.FC<ChangePricePopoverProps> = ({onSuccess}) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const changePriceController = new ChangePriceController(dispatch);
    const [selectedType, setSelectedType] = useState<string>(ChangePriceTypes.PRICE);
    const [value, setValue] = useState<number>(0)
    const {isOpen, onOpen, onClose} = useDisclosure();
    const handleSaveClick = () => {
        const request: ChangePriceAddedRequest = {
            id: -1,
            type:selectedType,
            value: value
        };
        changePriceController.addChangePrice(request).then((res) => {
            const isError = !!res.None;
            toast({
                position: "top",
                title: isError
                    ? "Failed to add price change."
                    : "Price change added successfully.",
                status: isError ? "error" : "success",
                duration: 5000,
                isClosable: true,
            });
            if (!isError) {
                if (onSuccess) {
                    onSuccess();
                }
                if (onClose) {
                    onClose(); // Закрыть Popover
                }
            }
        });
    };

    return (
        <Popover closeOnBlur={true} isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement='bottom-end'>
            {({isOpen, onClose}) => (
                <>
                    <PopoverTrigger>
                        <Text textDecoration={"underline"} fontSize={14}
                              _hover={{textDecoration: "unset", cursor: "pointer"}}
                        >{'Add change price'}</Text>
                    </PopoverTrigger>
                    <PopoverContent
                        style={{
                            padding: 8,
                            maxHeight: "calc(67vh - 20px)",
                            overflowY: "auto",
                            minWidth: "50%",
                        }}
                    >
                        <PopoverBody p={0} fontSize={14}>
                            <FormControl pt={1}>
                                <FormLabel fontSize={14}>Select Content Type</FormLabel>
                                <Select fontSize={14}  height={"30px"}
                                    defaultValue={selectedType}
                                    onChange={(event) => setSelectedType(event.target.value)}>
                                    {Object.values(ChangePriceTypes).map((type) => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl py={1} >
                                <FormLabel fontSize={14}>value (%)</FormLabel>
                                <Input fontSize={14} height={"30px"} type="number" value={value} onChange={(ev: any) => setValue(ev.target.value)}/>
                            </FormControl>
                            <Flex position="relative" w={"100%"}>
                                <MainButton ml={"auto"} onClick={handleSaveClick}> Save </MainButton>
                            </Flex>
                        </PopoverBody>
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
};

export default ChangePricePopover;
