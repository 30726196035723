import {PayloadAction, Reducer} from "@reduxjs/toolkit"
import {Product} from "../../shared/entities/Product/Product";
import {ProductCostCalculateUtils} from "../../shared/utilities/ProductCostCalculateUtils";
import {Dealership} from "../../shared/entities/Users/Dealership";

export type CartState = {
    cartItems: Array<Product> | [],
    subTotal: number;
    dealership: Dealership | undefined;
};

const initialState: CartState = {
    cartItems: [],
    subTotal: 0,
    dealership: undefined,
};

export enum ActionTypes {
    ADD_TO_CART = "ADD_TO_CART",
    UPDATE_CART_PRODUCTS = "UPDATE_CART_PRODUCTS",
    REMOVE_FROM_CART = "REMOVE_FROM_CART",
    REMOVE_ALL_CART = "REMOVE_ALL_CART",
    UPDATE_DEALERSHIP = "UPDATE_DEALERSHIP",
}

// Сохранение корзины в куки
const saveCartToStorage = (cartItems: Array<Product>) => {
    // Cookies.set("cartItems", JSON.stringify(cartItems), {expires: 7});
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));

};

// Загрузка корзины из куки
const loadCartFromStorage = (): Array<Product> => {
    const savedCartItems = localStorage.getItem("cartItems");
    // const savedCartItems = sessionStorage.getItem("cartItems");
    // const savedCartItems = Cookies.get("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
};

export const CartReducer: Reducer<CartState, PayloadAction<Product>> = (
    state = {
        ...initialState,
        cartItems: loadCartFromStorage(),
        subTotal: calculateSubTotal(loadCartFromStorage(), undefined)
    },
    action: PayloadAction<any>
) => {
    let updatedCartItems: Array<Product>;
    switch (action.type) {
        case ActionTypes.ADD_TO_CART:
            const product = action.payload as Product;
            const existingProduct = state.cartItems.find(
                (item) => item.id === product.id
            );
            if (existingProduct) {
                updatedCartItems = state.cartItems.map((item) =>
                    item.id === product.id
                        ? {...item, quantity: product.quantity}
                        : item
                );
            } else {
                updatedCartItems = [...state.cartItems, product];
            }
            saveCartToStorage(updatedCartItems);
            state.subTotal = calculateSubTotal(updatedCartItems, state.dealership);
            return {
                ...state,
                cartItems: updatedCartItems,
            };
        case ActionTypes.UPDATE_CART_PRODUCTS:
            const newProducts = action.payload as Array<Product>;

            // Создаем карту количества из старого списка по ID
            const oldQuantityMap = new Map(
                state.cartItems.map((item) => [item.id, item.quantity])
            );

            // Обновляем продукты: добавляем старое количество, если продукт уже был в корзине
            updatedCartItems = newProducts.map((newProduct) => ({
                ...newProduct,
                quantity: oldQuantityMap.get(newProduct.id) || 0, // Сохраняем старое количество или ставим 0
            }));
            // Сохраняем обновленную корзину в хранилище
            saveCartToStorage(updatedCartItems);
            state.subTotal = calculateSubTotal(updatedCartItems, state.dealership);
            return {
                ...state,
                cartItems: updatedCartItems
            };
        case ActionTypes.REMOVE_FROM_CART:
            updatedCartItems = state.cartItems.filter(
                (item) => item.id !== action.payload.id
            );
            saveCartToStorage(updatedCartItems);
            state.subTotal = calculateSubTotal(updatedCartItems, state.dealership);
            return {
                ...state,
                cartItems: updatedCartItems,
            };

        case ActionTypes.REMOVE_ALL_CART:
            updatedCartItems = [];
            saveCartToStorage(updatedCartItems);
            state.subTotal = calculateSubTotal(updatedCartItems, state.dealership);
            return {
                ...state,
                cartItems: updatedCartItems,
            };
        case ActionTypes.UPDATE_DEALERSHIP:
            state.subTotal = calculateSubTotal(loadCartFromStorage(), action.payload);
            return {
                ...state,
                dealership: action.payload,
            };
        default:
            return state;
    }
};

const calculateSubTotal = (cartItems: Array<Product>, selectedDealer: any): number => {
    let subtotal = 0;
    cartItems.forEach((product) => {
        subtotal += ProductCostCalculateUtils.calculatePrice(product, selectedDealer) * product.quantity;
    });
    return subtotal;
};