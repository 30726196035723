export type ChangePrice = {
    id: number
    value: string
    type: ChangePriceTypes
    createdAt: number
}

export type ChangePriceAddedRequest = {
    id?: number
    value: number
    type: string
}

export enum ChangePriceTypes {
    PRICE = "PRICE",
    SHIPPING = "SHIPPING"
}

export interface ReqChangePriceConfig {
    params: {
        page: number;
        size: number;
    };
    data: {
        [key: string]: any
    };
}