import {PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "react"
import {ActionTypes} from "../../redux/Order/OrderReducer";
import {OrderRepositoryInstance} from "../../repository/Order/OrderRepository";
import {CheckoutRequest, CheckoutRequestNewUser} from "../../shared/entities/Client/CheckoutRequest";
import {CartService} from "../Cart/CartService";

/**
 * ClientService.
 *
 */
export class OrderService {
    private dispatch: Dispatch<PayloadAction<any>>
    private repository = OrderRepositoryInstance
    private cartService: CartService;

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
        this.cartService = new CartService(this.dispatch)
    }

    public async getOrder(id: number, unregistered: boolean, email?: string) {
        return this.repository.getOrder(id, unregistered, email).then(res => {
            this.dispatch({
                type: ActionTypes.ORDER,
                payload: res?.Some || []
            })
            return res
        })
    }


    public async orderCheckout(data: CheckoutRequest) {
        return await this.repository.orderCheckout(data).then(res => {
            // localStorage.removeItem('cartItems');
            // this.dispatch({type: "REMOVE_ALL_CART", payload: []});
            this.cartService.removeAllCart()
            this.dispatch({
                type: ActionTypes.ORDER_CHECKOUT,
                payload: res.Some
            })
            return res
        })
    }

    public async createOrderNewUser(data: CheckoutRequestNewUser) {
        return await this.repository.createOrderNewUser(data).then(res => {
            if (!res?.None) {
                this.cartService.removeAllCart()
            }
            this.dispatch({
                type: ActionTypes.ORDER_CHECKOUT,
                payload: res.Some
            })
            return res
        })
    }

    public async invoicePdf(data: any) {
        return this.repository.invoicePdf(data).then(res => {
            this.dispatch({
                type: ActionTypes.INVOICE_PDF,
                payload: res?.Some || []
            })
            return res
        })
    }

    public async packingSleep(data: any) {
        return this.repository.packingSleep(data).then(res => {
            this.dispatch({
                type: ActionTypes.INVOICE_PDF,
                payload: res?.Some || []
            })
            return res
        })
    }

    public async orderConfirmState(data: any) {
        return this.repository.orderConfirmState(data).then(() => {
        })
    }

    public async removeOrder(id: number) {
        return this.repository.removeOrder(id).then((res) => {
            return res;
        })
    }

    public async sendInvoice(data: any) {
        return this.repository.sendInvoice(data).then(res => {
            this.dispatch({
                type: ActionTypes.SEND_INVOICE,
                payload: res?.Some || []
            })
            return res
        })
    }

    public async printPressed(data: any) {
        return this.repository.printPressed(data).then(res => {
            return res
        })
    }

    public async getOrderList(data: any) {
        return await this.repository.getOrderList(data).then(res => {
            this.dispatch({
                type: ActionTypes.GET_ORDER_LIST,
                payload: res.Some
            })
            return res
        })
    }

}