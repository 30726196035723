import {PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "react"
import {ChangePriceRepositoryInstance} from "../../repository/Product/ChangePriceRepository";
import {ActionTypes} from "../../redux/Product/ChangePriceReducer";

/**
 * UserService.
 *
 */
export class ChangePriceService {
    private dispatch: Dispatch<PayloadAction<any>>
    private repository = ChangePriceRepositoryInstance

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
    }

    public async getChangePriceAll(data: any) {
        return await this.repository.getChangePriceAll(data).then(res => {
            this.dispatch({
                type: ActionTypes.CHANGE_PRICE_LIST_ALL,
                payload: res.Some
            })
            return res.Some
        })
    }

    public async rollbackChangePrice(params: {}) {
        return await this.repository.rollbackChangePrice(params).then(res => {
            return res.Some
        })
    }

    public async addChangePrice(data: any) {
        return await this.repository.addChangePrice(data).then(res => {
            return res.Some
        })
    }

}



