import {Option} from "../../utilities/OptionT";
import {ProductTypes} from "../../../common/constants";

export type PriceProduct = {
    product: Product;
    vendorProduct: boolean;
    orderIds: Array<number>;
}

export type Product = {
    id: number;
    parentId: number;
    name: string;
    type: ProductTypes;
    price: number;
    primaryImageUuid: string;
    shippingCost: number;
    shippingLocal: number;
    taxCost?: number;
    shippingStep: number;
    shippingMinSize: number;
    parentName: string,
    parentType: ProductTypes,
    parentShippingCost: number;
    parentShippingLocal: number;
    parentShippingMinSize: number;
    parentShippingStep: number;
    description: string;
    descriptionCart: string;
    descriptionPacking: string;
    unitOfMeasurement: string;
    video: Array<Content>;
    images: Array<ImageDTO>;
    breadcrumbs: Array<BreadLink>;
    discountsDTO?: Array<Discount>;
    priority: number;
    quantity: number;
    finalPrice?: number;
    discount?: number;
    orderBy: number;
    uuid: string | null;
    imageName: string;
    countOrder: number;
    packingSize: number;
    updateLast: number;
    startValue: number;
    startAdminValue: number;
    quantityStep: number;
    archive: boolean;
    noTax: boolean;
    hiddenProduct: boolean;
    showCart: boolean;
    outlinesInvoice: boolean;
    file?: Option<Blob> | null;
    url: string;
}

export type Content = {
    id: number
    value: string
    title: string
    order: number
}

export type BreadLink = {
    id: number
    url: string
    value: string
}

export enum ContentTypes {
    NO_SHIPP_DEALER = "NO_SHIPP_DEALER",
    DISCOUNT_DEALER = "DISCOUNT_DEALER",
    MARKUP_DEALER = "MARKUP_DEALER",
    TAX_DEALER = "TAX_DEALER"
}

export const ContentTypeUtils = {
    getCostState(contentType: ContentTypes): { name: string, color: string, func: string } {
        switch (contentType) {
            case ContentTypes.DISCOUNT_DEALER:
                return {name: "Discount", color: "lightpink", func: "price*(1-val/100)"};
            case ContentTypes.MARKUP_DEALER:
                return {name: "Markup", color: "lightgoldenrodyellow", func: "price*(1+val/100)"};
            case ContentTypes.NO_SHIPP_DEALER:
                return {name: "Shipping", color: "lightblue", func: "cost*(val/100)"};
            case ContentTypes.TAX_DEALER:
                return {name: "Tax", color: "lightgreen", func: "cost*(val/100)"};
            default:
                return {name: "Undef", color: "unset", func: "no"};
        }
    }
};

export type Discount = {
    id: number
    value: number
    dealershipId: number
    type: ContentTypes
}

export type ImageDTO = {
    name: string
    uuid: string
}

export interface ImageItem {
    file: File | null;
    url: string;
    uuid: string;
}

export class ProductClass implements Product {
    id: number;
    parentId: number;
    name: string;
    video: Array<Content>;
    discountsDTO?: Array<Discount>;
    images: Array<ImageDTO>;
    breadcrumbs: Array<BreadLink>;
    orderBy: number;
    type: ProductTypes;
    price: number;
    primaryImageUuid: string;
    shippingCost: number;
    shippingLocal: number;
    shippingStep: number;
    shippingMinSize: number;
    parentType: ProductTypes;
    parentName: string;
    parentShippingCost: number;
    parentShippingLocal: number;
    parentShippingMinSize: number;
    parentShippingStep: number;
    description: string;
    descriptionCart: string;
    url: string;
    descriptionPacking: string;
    unitOfMeasurement: string;
    priority: number;
    quantity: number;
    packingSize: number;
    startValue: number;
    startAdminValue: number;
    quantityStep: number;
    archive: boolean;
    noTax: boolean;
    hiddenProduct: boolean;
    showCart: boolean;
    outlinesInvoice: boolean;
    uuid: string | null;
    imageName: string;
    countOrder: number;
    updateLast: number;
    file?: Option<Blob> | null;


    constructor(type = ProductTypes.PRODUCT) {
        this.id = 0;
        this.parentId = 0;
        this.name = "Add new product";
        this.parentName = "Add new Parent product";
        this.video = [];
        this.discountsDTO = [];
        this.images = [];
        this.breadcrumbs = [];
        this.orderBy = 0;
        this.type = type;
        this.price = 0;
        this.primaryImageUuid = "";
        this.shippingCost = 0;
        this.shippingLocal = 0;
        this.shippingStep = 0;
        this.shippingMinSize = 1;
        this.parentType = ProductTypes.PRODUCT;
        this.parentShippingCost = 0;
        this.parentShippingLocal = 0;
        this.parentShippingMinSize = 1;
        this.parentShippingStep = 0;
        this.description = "";
        this.descriptionCart = "";
        this.descriptionPacking = "";
        this.unitOfMeasurement = "";
        this.priority = 0;
        this.updateLast = 0;
        this.quantity = 0;
        this.packingSize = 1;
        this.startValue = 1;
        this.startAdminValue = 1;
        this.quantityStep = 1;
        this.archive = false;
        this.noTax = false;
        this.showCart = false;
        this.outlinesInvoice = false;
        this.hiddenProduct = false;
        this.uuid = "new-product-uuid";
        this.imageName = "image.jpg";
        this.countOrder = 0;
        this.file = null;
        this.url = "";
    }
}

export type ProductRequest = {
    id: number;
    parentId: number;
    name: string;
    url?: string;
    type: string;
    price: number;
    primaryImageUuid: string;
    shippingCost: number;
    shippingLocal: number;
    shippingStep: number;
    shippingMinSize: number;
    noTax: boolean;
    hiddenProduct: boolean;
    description: string;
    descriptionCart: string;
    descriptionPacking: string;
    unitOfMeasurement: string;
    orderBy: number;
    packingSize: number;
    startValue: number;
    startAdminValue: number;
    quantityStep: number;
    archive: boolean;
    showCart: boolean;
    outlinesInvoice: boolean;
    edit: boolean
    video: Array<Content>;
    discounts: Array<Discount>;
    images: Array<ImageDTO>;
}

export type ProductChangeType = {
    id: number;
    type: string;
}

export type ProductChangeParent = {
    id: number;
    parent: number;
}

export type ProductChangeOrderBy = {
    id: number;
    newPlaceProductId: number;
}

export type ProductSetArchive = {
    id: number;
    flag: boolean;
}

export type ProductList = {
    products: Array<Product> | []
}